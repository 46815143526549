/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 360px;
  max-height: 680px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.popUps .popUpsMode .logTitle {
  font-size: 36px;
  padding-top: 65px;
}
.popUps .popUpsMode .logMain {
  width: 83.33333333%;
  /* 选择登录 */
  /* 邮箱手机号登录 */
}
.popUps .popUpsMode .logMain .logTips {
  padding: 50px 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.popUps .popUpsMode .logMain .logChangBtn {
  min-height: 200px;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  transition: all 0.3s linear;
}
.popUps .popUpsMode .logMain .logChangBtn:hover {
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  color: #fff;
}
.popUps .popUpsMode .logMain .logUnder {
  border-top: 1px solid #19618D;
  margin-top: 40px;
  padding: 42px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  text-align: center;
}
.popUps .popUpsMode .logMain .logForm {
  width: 100%;
}
.popUps .popUpsMode .logMain .logTex {
  width: 100%;
  height: 48px;
  font-size: 18px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  margin-top: 20px;
  box-sizing: border-box;
}
.popUps .popUpsMode .logMain .logBtn {
  width: 100%;
  height: 48px;
  font-size: 24px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.popUps .popUpsMode .logMain .logForgotPad {
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
}
.popUps .popUpsMode .logMain .fromTipsBox {
  height: 60px;
}
.popUps .popUpsMode .logMain .fromTipsBox .fromTips {
  height: 20px;
  color: #FF0000;
  font-size: 14px;
  line-height: 20px;
}
.popUps .popUpsMode .logMain .hasTips {
  border-color: #FF0000;
}
.addMetaTitle {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  top: 20px;
  width: 100%;
  border-bottom: 1px solid #e5e8eb;
  padding: 12px 0;
  text-align: center;
}
.addMetaContent {
  width: 100%;
  min-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 68px;
}
.addMetaRow {
  width: 96%;
  margin: 0 auto;
}
.addMetaDesc {
  flex-basis: 100%;
  max-width: 100%;
  line-height: 24px;
  margin-bottom: 16px;
}
.addMetaColName {
  flex-basis: 45%;
  max-width: 45%;
  margin-bottom: 16px;
}
.btn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #2081e2;
  color: #2081e2;
  bottom: 0px;
}
.addMetaInput {
  box-sizing: border-box;
  height: 36px;
  margin-bottom: 16px;
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
}
.bottomBtn {
  align-content: center;
  height: 40px;
  padding: 12px 0px;
  width: 100%;
  border-top: 1px solid #e5e8eb;
  position: absolute;
  bottom: 0px;
}
.selectItem {
  width: 100%;
}
