/* light */
/* dark */
.pageBg {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/download/bg.png) no-repeat;
  background-position: center;
  background-size: cover;
}
.codeBox {
  width: 100%;
  background-color: #fff;
  text-align: center;
  border-radius: 10px;
}
.codeBox img {
  width: calc(100% - 20px);
}
