/* light */
/* dark */
.settings {
  max-width: 1280px;
  padding: 24px 0px;
  margin: 0 auto;
}
.settings .title {
  font-weight: 600;
  font-size: 40px;
  color: #04111d;
  margin-bottom: 16px;
  margin-top: 28px;
}
.btn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.btn:hover:not([disabled]) {
  color: #ffffff;
  background-color: #1868b7;
  transition: all 0.2s ease 0s;
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
}
.cardBottom > span {
  color: #999999;
  font-size: 14px;
}
.cardBottom > p {
  margin-top: 8px;
  height: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.divider {
  background: #E1E1E1;
  width: 0.6px;
  height: 25px;
  top: -50%;
  left: 8px;
  position: relative;
  float: right;
}
.bottomContent {
  margin-top: 55px;
  background-color: #01A8FF;
}
.bottomText {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottomText > span {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 24px;
}
.bottomText > p {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 15px;
}
.card {
  border-radius: 12px;
  border: 1px solid #D1D1D1;
  overflow: hidden;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.1);
  margin-bottom: 16px;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
}
.card:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
}
.card .image {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  position: relative;
}
.card .childImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.card .nftName {
  font-size: 16px;
  font-weight: bold;
  padding: 6px 12px;
}
.card .cardTips {
  width: 100%;
  margin: 0 auto !important;
  padding-top: 9px;
  text-align: center;
}
.card .cardTips .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  height: 48px;
}
.card .cardTips .orginInfo {
  font-size: 14px;
  height: 22px;
}
.card .cardTips .orginInfo .orginInfoImg {
  width: 22px;
  height: 22px;
  border-radius: 22px;
}
.card .cardTips .orginInfo .orginInfoImg img {
  width: 22px;
  height: 22px;
}
.card .cardTips .orginInfo .orginInfoName {
  width: calc(100%-24px);
  box-sizing: border-box;
  padding-left: 7px;
  line-height: 22px;
}
.card .cardTips .orginInfo .orginInfoSymbol {
  text-align: right;
  font-size: 16px;
  line-height: 22px;
}
.card .mask {
  position: absolute;
  z-index: 9;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin: 12px 12px 0px 0px;
  transition: opacity 0.1s ease-in 0s;
  opacity: 0;
  background: rgba(255, 255, 255, 0.9);
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.avatarDiv {
  width: 48px;
  height: 48px;
  background-color: #fbfdff;
  border: 3px solid #fbfdff;
  box-shadow: rgba(14, 14, 14, 0.6) 0px 0px 2px 0px;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: -24px;
}
.exploreColl {
  margin-top: 20px;
  height: 36px;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  font-weight: 400;
  font-size: 16px;
}
.exploreColl > span {
  font-weight: 400;
  font-size: 16px;
  max-width: 80%;
  color: #707a83;
}
.card:hover .mask {
  opacity: 1;
}
.card:hover .mask:hover {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  opacity: 1;
}
.exploreRow {
  min-height: 680px;
}
.exploreItem {
  flex-basis: 33%;
  max-width: 33%;
}
/* lg */
@media (max-width: 1200px) {
  .exploreItem {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
}
/* md */
@media (max-width: 992px) {
  .exploreItem {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
}
/* sm */
@media (max-width: 768px) {
  .exploreItem {
    flex-basis: 50%;
    max-width: 50%;
  }
}
/* sx */
@media (max-width: 576px) {
  .exploreItem {
    flex-basis: 100%;
    max-width: 100%;
  }
}
