/* light */
/* dark */
.gridMain {
  background: #D5D5D5;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
}
.gridMain .boardBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.gridMain .boardBox::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
          height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.gridMain .boardBoxItem {
  width: 10%;
  height: 10%;
  position: absolute;
  z-index: 3;
}
.gridMain .boardBoxItem::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
          height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.gridMain .boardBoxItem.saleStatus0 {
  background: #01a8ff;
}
.gridMain .boardBoxItem.saleStatus1 {
  background: #FFA000;
}
.gridMain .boardBoxItem.saleStatus2 {
  background: #33CC66;
}
.gridMain .boardBoxItem.saleStatus3 {
  background: #FF48ED;
}
.gridMain .boardBoxItem.saleStatus4 {
  background: #D5D5D5;
}
.gridMain .gridCur::before {
  content: '';
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 2px solid #FF48ED;
  box-sizing: border-box;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 4;
}
.gridMain .boardBoxGrid_Y {
  width: 100%;
  height: 10%;
  position: absolute;
  z-index: 3;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  top: -10%;
}
.gridMain .boardBoxGrid_Z {
  width: 10%;
  height: 100%;
  position: absolute;
  z-index: 3;
  left: -10%;
  display: grid;
  grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
