/* light */
/* dark */
.main {
  width: 100%;
  min-height: 72px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 110;
  transition: top 0.5s ease 0s;
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  margin: 0 !important;
  padding: 0 !important;
}
.nav {
  width: 100%;
  min-height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
  margin: 0 !important;
}
.logoImg {
  width: 230px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navMenu {
  width: 100%;
  position: relative;
}
.navMenuLeft {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  /**/
  padding: 0 calc(1 / 24 * 100%) 0 calc(1 / 18 * 100%);
}
.navMenuLeft a:hover {
  color: #01A8FF;
}
.loginBtn {
  flex: 0 0 100px;
  width: 100px;
  height: 40px;
  padding: 0 !important;
  background: #01A8FF;
  border-radius: 20px !important;
  color: #fff;
}
.menuNavAbbreviation {
  width: 40px;
  height: 40px;
  margin: 0 0 0 calc(1 / 24 * 100%);
  display: none;
}
.search {
  width: 300px;
  height: 40px;
  background: #F2F4F6;
  border-radius: 20px;
  padding: 0 10px;
  flex-direction: row-reverse;
  box-sizing: border-box;
  visibility: hidden;
}
.searchIcon {
  width: 40px;
  height: 40px;
  display: block;
  background: url('../../assets/images/menu/search-icon.png') no-repeat;
  background-position: center center;
}
.searchInput {
  flex: 1;
  height: 38px;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  box-sizing: border-box;
  padding-left: 10px;
}
.searchInput:focus + .searchIcon {
  background: url('../../assets/images/menu/search-hover-icon.png') no-repeat;
  background-position: center center;
}
.modeBtn {
  padding: 0 10px;
}
.line {
  width: 21px;
  height: 48px;
  padding-right: 5px;
}
.line::before {
  content: '';
  display: block;
  height: 48px;
  border-left: 1px dashed rgba(0, 0, 0, 0.3);
}
.localesBtn {
  white-space: nowrap;
  cursor: pointer;
  line-height: 26px;
  width: 84px;
  height: 26px;
  text-align: center;
}
.localesBtn div {
  padding: 0 10px;
  border-radius: 10px;
}
.localesBtn div:hover {
  color: #000;
  background-color: #E6EFFE;
}
.localesIcon {
  width: 14px;
  height: 7px;
  vertical-align: middle;
}
/*隐藏导航*/
.NavMain {
  display: none;
}
.NavMain .NavMenuBg {
  position: fixed;
  inset: 0px;
  z-index: 70;
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0s;
  background-color: rgba(0, 0, 0, 0.68);
  text-indent: -9999px;
}
.NavMain .NavMenu {
  display: block !important;
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 420px;
  z-index: 80;
  height: calc((100% - 0px) - 72px);
  background: #ffffff;
  border: 1px solid #e5e8eb;
  overflow: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.NavMain .NavMenu a,
.NavMain .NavMenu .userNavMenu {
  color: rgba(4, 17, 29, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
  overflow: hidden;
  padding: 0px 20px;
  position: relative;
}
.NavMain .NavMenu a:hover,
.NavMain .NavMenu .userNavMenu:hover {
  color: #01A8FF;
}
.NavMain .NavMenu a::after,
.NavMain .NavMenu .userNavMenu::after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid rgba(133, 134, 134, 0.75);
  position: absolute;
  bottom: 0;
}
.userNavMenu {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.userNavMenu:hover .userLinkNav {
  display: block;
}
.userNavMenu .userBtn {
  height: 68px;
  display: inline-block;
  line-height: 68px;
}
.userNavMenu .userBtn:hover {
  color: #01A8FF;
}
.userNavMenu .userLinkNav {
  display: none;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.1);
  border-radius: 10px;
  overflow: hidden;
  width: 200px;
  height: auto;
  position: absolute;
  right: -10px;
  top: 58px;
  transition: all 0.5s ease 0s;
}
.userNavMenu .userLinkNav.navLink {
  left: -25px;
  right: auto;
}
.userNavMenu .userLinkNav a,
.userNavMenu .userLinkNav .logOutBtn,
.userNavMenu .userLinkNav .navItem {
  display: block;
  width: 100%;
  height: 53px;
  padding: 0 20px;
  font-size: 14px;
  font-family: Arial;
  font-weight: bold;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.userNavMenu .userLinkNav a:hover,
.userNavMenu .userLinkNav .logOutBtn:hover,
.userNavMenu .userLinkNav .navItem:hover {
  background: #E6EFFE;
  color: #01A8FF;
}
.userNavMenu .userLinkNav a:hover .userNavIcon,
.userNavMenu .userLinkNav .logOutBtn:hover .userNavIcon,
.userNavMenu .userLinkNav .navItem:hover .userNavIcon {
  display: none;
}
.userNavMenu .userLinkNav a:hover .userNavIconHover,
.userNavMenu .userLinkNav .logOutBtn:hover .userNavIconHover,
.userNavMenu .userLinkNav .navItem:hover .userNavIconHover {
  display: block;
}
.userNavMenu .userLinkNav a .userNavIconHover,
.userNavMenu .userLinkNav .logOutBtn .userNavIconHover,
.userNavMenu .userLinkNav .navItem .userNavIconHover {
  display: none;
}
.userNavMenu .userLinkNav a img,
.userNavMenu .userLinkNav .logOutBtn img,
.userNavMenu .userLinkNav .navItem img {
  margin-right: 10px;
}
.userNavMenu .userLinkNav .logOutBtn,
.userNavMenu .userLinkNav .navItem:last-of-type {
  border: none;
}
.userNavMenu .lightUserLinkNav {
  background-color: #fff;
}
.userNavMenu .lightUserLinkNav a,
.userNavMenu .lightUserLinkNav .logOutBtn {
  color: #000;
}
.userNavMenu .darkUserLinkNav {
  background-color: #000;
  box-shadow: 0px 5px 16px 0px rgba(255, 255, 255, 0.15);
}
.userNavMenu .darkUserLinkNav a,
.userNavMenu .darkUserLinkNav .logOutBtn {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.userNavMenu .darkUserLinkNav a:hover,
.userNavMenu .darkUserLinkNav .logOutBtn:hover {
  background: #1A1A1A;
}
.userAvatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  vertical-align: middle;
}
/* 正常是xl */
/* lg */
@media (max-width: 1200px) {
  .navMenuLeft {
    display: none !important;
    margin-left: 0;
  }
  .navMenuRight {
    width: 100% !important;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
  .menuNavAbbreviation {
    display: flex;
  }
  .menuNavAbbreviation .menuNavBtn {
    width: 32px;
    height: 32px;
  }
  .NavMain {
    display: block;
  }
  .userNavMenu .navLink {
    display: block;
    position: initial;
    width: 100%;
    box-shadow: none;
    top: auto;
    left: auto;
    border-radius: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
/* md */
@media (max-width: 992px) {
  .navMenuRight {
    padding-left: 10px;
  }
  .search {
    flex: 1;
    margin-right: calc(1 / 24 * 100%);
  }
  .menuNavAbbreviation {
    display: flex;
  }
}
/* sm */
@media (max-width: 768px) {
  .modeBtn {
    display: none !important;
  }
  .loginBtn {
    font-size: 16px;
    flex: 0 0 80px;
  }
  .menuNavAbbreviation {
    margin-left: 0;
    margin-right: calc(1 / 24 * 100%);
  }
}
/* sx */
.lightBodyBg {
  background-color: #F2F4F6;
  color: #000;
}
.navlight {
  background-color: #fff;
}
.navMenulight {
  color: #000;
}
.linklight a {
  color: #000;
}
.searchlight {
  background: #F2F4F6;
}
.darkBodyBg {
  background-color: #090724;
  color: #fff;
}
.navdark {
  background-color: #000;
}
.navMenudark {
  color: #fff;
}
.linkdark a {
  color: #fff;
}
.searchdark {
  background: #1A1A1A;
}
.searchdark .searchInput {
  color: #fff;
}
.linedark::before {
  border-color: rgba(255, 255, 255, 0.2);
}
