/* light */
/* dark */
.settings {
  max-width: 1280px;
  padding: 24px 0px;
  margin: 0 auto;
}
.settings .title {
  font-weight: 600;
  font-size: 40px;
  color: #04111d;
  margin-bottom: 16px;
  margin-top: 28px;
}
.required.field > label:after {
  margin: 0px 0px 0px 4px;
  content: '*';
  color: #DB2828;
  display: inline-block;
  vertical-align: top;
}
.field {
  clear: both;
  margin: 0px 0px 12px;
}
.field > label {
  display: block;
  margin: 18px 0px 8px 0px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}
.field > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #707a83;
  margin: 2px 0 8px 0;
}
.field:last-child {
  margin-bottom: 0px;
}
.logoImg {
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
.featureImg {
  border-radius: 10px;
  height: 200px;
  width: 300px;
}
.bannerImg {
  border-radius: 10px;
  height: 200px;
  width: 700px;
}
.inputItem {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  border: 1px solid #e5e8eb;
  border-radius: 10px;
  outline: none;
  padding: 0px 12px 0px 12px;
  flex: 1 0 0%;
  font-size: 16px;
}
.inputItem:focus {
  box-shadow: 0px 0px 6px 6px rgba(34, 23, 20, 0.1);
}
.textAreaItem {
  width: 100%;
  height: auto;
  padding: 12px;
  resize: vertical;
  border: 1px solid #e5e8eb;
  background-color: #ffffff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 26px;
  min-height: 26px;
  outline: none;
  box-sizing: border-box;
}
.createBtn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.createBtn:disabled {
  opacity: 0.2;
}
