.redit-root {
  /* width: 500px; */
  outline: none;
  /* overflow: hidden; */
  position: relative;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.redit-active {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(209, 209, 209, 0.3);
}
.redit-active > .redit-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  /* animation: 0.6s openMenu; */
}
.redit-active > .redit-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #fff;
}
.redit-body {
  min-height: 150px;
  max-height: 230px;
  width: 100%;
  /* padding: 5px 10px; */
  overflow-y: auto;
  outline: none;
  line-height: 1.42857143;
  border: none;
  box-sizing: border-box;
  resize: none;
  color: #555;
  cursor: text;
  border-radius: 10px 10px 0 0;
  /* background-color: #fff; */
  word-break: break-all;
  background: #F2F2F2;
  padding: 10px;
  transition: all 0.2s ease-in;
  /* font-size: 16px; */
  /* letter-spacing: 0px; */
  background-size: 100%;
  font-size: medium;
  /* font-size: 20px; */
}
.redit-body:empty:before {
  content: attr(data-placeholder);
  color: #bbb;
}
.redit-body:focus:before {
  content: none;
}
.redit-body img,
.showEmotorMsg img {
  /* position: relative; */
  /* top: 5px; */
  margin: 2px;
  height: 25px;
  width: 25px;
  vertical-align: middle;
}
.showEmotorMsg {
  word-break: break-all;
}
.redit-menu {
  position: relative;
  top: 0;
  overflow-y: hidden;
  /* overflow-x: auto; */
  width: 100%;
  height: 50px;
  min-width: 200px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #e5e5e5;
  /* animation: 0.6s closeMenu; */
  display: flex;
  justify-content: flex-end;
}
.menu-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  caret-color: transparent;
  top: 0;
}
.edtailBtn {
  padding: 5px 25px !important;
  font-size: 14px !important;
  margin: 0 30px 0 20px;
}
.menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
/* 
.menu-item:hover {
    background-color: #f5f6f7;
} */
.menu-item-hover {
  background-color: #f5f6f7;
  transition: all 0.3s;
}
.menu-item-img {
  width: 30px;
  height: 30px;
}
.redit-select {
  display: none;
  position: absolute;
  width: 100%;
  height: 0px;
  overflow: hidden;
  border-top: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(209, 209, 209, 0.5);
  box-sizing: content-box;
  background-color: #f5f6f7;
}
.selected {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  transition: all 0.3s;
  z-index: 90;
}
.select-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: content-box;
  /* padding: 10px; */
  float: left;
  font-size: 28px;
  line-height: 48px;
  width: 48px;
  height: 48px;
}
.select-item:hover {
  background-color: #e5e5e5;
  border-radius: 8px;
  font-size: 32px;
}
.select-item:hover > .select-item-img {
  transform: scale(1.2);
  transition: all 0.3s;
}
.select-item:active > .select-item-img {
  transform: scale(1.1);
  transition: all 0.2s;
}
.select-item-img {
  width: 28px;
  height: 28px;
  transform: scale(1);
  transition: all 0.3s;
}
/* 菜单的打开和关闭动画 */
@keyframes openMenu {
  0% {
    height: 0;
    background-color: #fff;
  }
  100% {
    height: 50px;
    background: #e5e5e5;
  }
}
@keyframes closeMenu {
  0% {
    background-color: #e5e5e5;
    height: 50px;
  }
  100% {
    background: #fff;
    height: 0;
  }
}
/* 更改滚动条样式 */
.redit-body::-webkit-scrollbar,
.selected::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.redit-body::-webkit-scrollbar-thumb,
.selected::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: skyblue;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
}
.redit-body::-webkit-scrollbar-track,
.selected::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
  border-radius: 10px;
}
/* 去除redit-menu横向滚动条 */
.redit-menu::-webkit-scrollbar {
  /* display: none; */
}
