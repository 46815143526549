/* light */
/* dark */
.appsMain {
  width: 100%;
  min-height: calc(100vh - 42px);
  margin-top: 10px;
  border-radius: 20px 20px 0 0;
  padding: 40px 40px 0;
  box-sizing: border-box;
  position: relative;
}
.bookContainer {
  padding: 0 !important;
}
.accordionContainer {
  width: 24%;
  height: 80%;
  overflow: auto;
  position: absolute;
  left: -21%;
  top: 50%;
  margin-top: -45%;
}
.accordionContainer .accordion {
  width: 147px;
  max-width: 100%;
  height: 45px;
  background: url(../../assets/images/apps/bookmark1.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding-left: 15px;
  box-sizing: border-box;
  float: right;
}
.accordionContainer .noAccordion {
  width: 147px;
  max-width: 100%;
  height: 45px;
  background: url(../../assets/images/apps/bookmark2-hover.png) no-repeat;
  background-size: 100% 100%;
  float: right;
  font-size: 15px;
  font-weight: bold;
  color: #000;
  transition: all 0.2s ease;
}
.accordionContainer .noAccordion:hover {
  width: 147px;
  max-width: 100%;
  height: 45px;
  background: url(../../assets/images/apps/bookmark1.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: #fff;
  font-size: 16px;
}
.accordionContainer .accordionContent {
  width: 100%;
  display: none;
  transition: all 0.2s ease-in;
}
.accordionContainer .accordionContent .listItem {
  width: 115px;
  max-width: 78%;
  height: 40px;
  background: url(../../assets/images/apps/bookmark2.png) no-repeat;
  background-position: center;
  background-size: 100% 100%;
  font-size: 13px;
  padding-left: 25px;
  color: #000;
  box-sizing: border-box;
}
.accordionContainer .accordionContent .listItem.curChap {
  background: url(../../assets/images/apps/bookmark2-green.png) no-repeat;
}
.navMore {
  background: #E6EFFE;
  top: 0px;
}
.navMore .navMoreBtn {
  height: 32px;
}
.navMore .navMoreBtn img {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes example {
  0% {
    top: 0px;
  }
  50% {
    top: 2px;
  }
  50% {
    top: -2px;
  }
  50% {
    top: 2px;
  }
  100% {
    top: 0px;
  }
}
.bookTopNav {
  width: 100%;
}
.bookTopNav .myNavBtn {
  padding-bottom: 20px;
}
.bookTopNav .myNavBtn:hover .showMyList {
  display: block;
}
.bookTopNav .myNavBtn .myIcons {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  text-indent: -9999px;
  transition: all 0.2s ease-in;
  position: relative;
}
.bookTopNav .myNavBtn .myIcons.myGrids {
  min-width: 40px;
  width: auto;
  border-radius: 40px;
  background: #F2F4F6;
  transition: all 0.2s ease-in;
  text-indent: 0;
  position: relative;
  overflow: hidden;
}
.bookTopNav .myNavBtn .myIcons.myGrids::before {
  content: '';
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../assets/images/apps/grids-icon.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.myGrids:hover {
  background-color: #E6EFFE;
  color: #01A8FF;
}
.bookTopNav .myNavBtn .myIcons.myGrids:hover::before {
  background: url(../../assets/images/apps/grids-icon-hover.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.myCollect {
  min-width: 40px;
  width: auto;
  border-radius: 40px;
  background: #F2F4F6;
  transition: all 0.2s ease-in;
  text-indent: 0;
  position: relative;
  overflow: hidden;
}
.bookTopNav .myNavBtn .myIcons.myCollect::before {
  content: '';
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../assets/images/apps/collection-icon.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.myCollect:hover {
  background-color: #E6EFFE;
  color: #01A8FF;
}
.bookTopNav .myNavBtn .myIcons.myCollect:hover::before {
  background: url(../../assets/images/apps/collection-icon-hover.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.myHistory {
  background: #F2F4F6 url(../../assets/images/apps/history-icon.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.myHistory:hover {
  background: #E6EFFE url(../../assets/images/apps/history-icon-hover.png) no-repeat;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.BuyGrid {
  width: auto;
  text-indent: 0;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background: #F2F4F6;
  background-position: center;
  cursor: pointer;
}
.bookTopNav .myNavBtn .myIcons.BuyGrid:hover {
  background: #E6EFFE;
  color: #01A8FF;
  background-position: center;
}
.bookTopNav .myNavBtn .myIcons.BuyGrid::before {
  content: '';
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url(../../assets/images/apps/buy-icon.png) no-repeat;
  background-position: center;
  background-size: 20px 20px;
}
.bookTopNav .myNavBtn .myIcons.BuyGrid:hover::before {
  background: url(../../assets/images/apps/buy-hover-icon.png) no-repeat;
  background-position: center;
  background-size: 20px 20px;
}
.bookTopNav .gridsSearch {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  padding: 0 10px;
  box-sizing: border-box;
}
.bookTopNav .gridsSearch.inGridsSearch {
  border-color: #01A8FF;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
}
.bookTopNav .gridsSearch .gridsSearchIcon {
  width: 40px;
  height: 40px;
  display: block;
  background: url(../../assets/images/apps/grids-search-icon.png) no-repeat;
  background-position: center center;
}
.bookTopNav .gridsSearch .gridsSearchInput {
  flex: 1;
  height: 38px;
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  box-sizing: border-box;
  padding-left: 10px;
  position: relative;
}
.bookTopNav .gridsSearch .gridsSearchInput:focus + .gridsSearchIcon {
  background: url('../../assets/images/apps/grids-search-icon-hover.png') no-repeat;
  background-position: center center;
}
.bookTopNav .gridsSearch .searchMain {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  position: relative;
  z-index: 9;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.1);
}
.bookTopNav .gridsSearch .searchMainTip {
  width: 100%;
  border-top: 1px solid #e5e8eb;
  box-sizing: border-box;
  padding: 15px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
}
.bookTopNav .gridsSearch .searchMainTip:hover {
  transition: all 0.2s ease 0s;
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  background-color: #fbfdff;
}
.bookTopNav .showMyList {
  width: 300px;
  height: 380px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 55px;
  margin-left: -150px;
  display: none;
  box-sizing: border-box;
}
.bookTopNav .showMyList::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  z-index: 9;
}
.bookTopNav .showMyList .MyListTitle {
  box-sizing: border-box;
  height: 46px;
  padding: 15px;
  box-shadow: 0px 0px 16px rgba(4, 17, 29, 0.15);
  background-color: #fbfdff;
  position: relative;
  z-index: 2;
  border-radius: 10px 10px 0 0;
}
.bookTopNav .showMyListItem {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  z-index: 9;
  position: absolute;
  left: 100%;
  top: 0px;
  display: none;
  box-sizing: border-box;
}
.bookTopNav .isShowMyListItem {
  display: block;
}
.bookTopNav .favouriteMain {
  width: 100%;
  height: 334px;
  padding: 0;
  box-sizing: border-box;
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
}
.bookTopNav .favouriteMain .favouriteItem {
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: relative;
  top: 0;
  padding: 10px;
}
.bookTopNav .favouriteMain .favouriteItem:hover {
  box-shadow: 0px 0px 5px 0px rgba(34, 23, 20, 0.2);
}
.bookTopNav .favouriteMain .favouriteItem:hover .favouriteImg,
.bookTopNav .favouriteMain .favouriteItem:hover .folderImg {
  border-color: #01A8FF;
}
.bookTopNav .favouriteMain .favouriteItem:hover h3 {
  color: #01A8FF;
}
.bookTopNav .favouriteMain .favouriteItem:hover .folderArrow {
  background: url(../../assets/images/apps/folder-more-icon-hover.png) no-repeat;
  background-position: 100%;
}
.bookTopNav .favouriteMain .favouriteItem .favouriteImg {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 10px;
}
.bookTopNav .favouriteMain .favouriteItem .folderImg {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 10px;
  background: url(../../assets/images/apps/folder-icon.png) no-repeat;
  background-size: 70% 70%;
  background-position: center;
  transition: all 0.2s ease-in;
}
.bookTopNav .favouriteMain .favouriteItem .folderImg.isfolderImg {
  background: url(../../assets/images/apps/folder-icon-open.png) no-repeat;
  background-size: 70% 70%;
  background-position: center;
}
.bookTopNav .favouriteMain .favouriteItem .favouriteTitle {
  line-height: 24px;
}
.bookTopNav .favouriteMain .favouriteItem .favouriteLot {
  padding: 8px 15px;
  display: inline-flex;
  height: 30px;
  line-height: 14px;
  box-sizing: border-box;
  background: #E6EFFE;
  border-radius: 30px;
}
.bookTopNav .favouriteMain .favouriteItem .favouriteLot img {
  width: 14px;
  height: 14px;
}
.bookTopNav .favouriteMain .favouriteItem .folderArrow {
  width: 9px;
  height: 15px;
  background: url(../../assets/images/apps/folder-more-icon.png) no-repeat;
  background-position: 100%;
}
.bookTopNav .favouriteMain .favouriteItem .gridItemCollect {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-size: 60% !important;
  background: #E6EFFE url(../../assets/images/menu/MyCollections-hover-icon.png) center center no-repeat;
}
.book {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: url(../../assets/images/apps/bookBk.png) no-repeat;
  background-position: center top;
  background-size: contain;
  position: relative;
}
.book .pagination {
  position: absolute;
  bottom: 5%;
  width: 100%;
}
.book .board {
  width: 66%;
  height: 66%;
  position: absolute;
  top: 12%;
  left: 50%;
  margin-left: -33%;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.3);
  border: 1px solid #363636;
}
.book .board .boardBox {
  position: relative;
  width: 100%;
  height: 100%;
  background: #D5D5D5;
}
.book .board .boardBox::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
                height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.book .board .boardBoxItem {
  width: 10%;
  height: 10%;
  position: absolute;
  z-index: 3;
}
.book .board .boardBoxItem::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
                height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.book .board .boardBoxItem.saleStatus0 {
  background: #01a8ff;
}
.book .board .boardBoxItem.saleStatus1 {
  background: #ffbb00;
}
.book .board .boardBoxItem.saleStatus2 {
  background: #33CC66;
}
.book .board .boardBoxItem .boxImg {
  width: 100%;
  height: 100%;
}
.book .board .boardBoxItem .boxImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.book .board .gridCur::before {
  content: '';
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 2px solid #FF48ED;
  box-sizing: border-box;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 4;
}
.book .board .boardBoxGrid_Y {
  width: 100%;
  height: 10%;
  position: absolute;
  z-index: 3;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  top: -10%;
}
.book .board .boardBoxGrid_Z {
  width: 10%;
  height: 100%;
  position: absolute;
  z-index: 3;
  left: -10%;
  display: grid;
  grid-template-rows: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}
.book .pageNum {
  width: 100%;
  position: absolute;
  bottom: -30px;
}
.book .pageTips {
  width: 100%;
  position: absolute;
  top: 0px;
  right: calc(-100% - 5px);
}
.book .pageTips .saleStatus div {
  line-height: 24px;
}
.book .pageTips .saleStatus::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border: 1px solid #333;
  background: #D5D5D5;
}
.book .pageTips .saleStatus.saleStatus0::before {
  background: #01a8ff;
}
.book .pageTips .saleStatus.saleStatus1::before {
  background: #ffbb00;
}
.book .pageTips .saleStatus.saleStatus2::before {
  background: #33CC66;
}
.minBookGrid {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  box-sizing: border-box;
  border: 1px solid #CCCCCC;
  position: relative;
  border-radius: 10px;
}
.minBookGrid::after {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
}
.minBookGrid .minBoard {
  width: 80%;
  height: 80%;
  /* padding-bottom: 70%; */
  background: #D5D5D5;
  position: absolute;
  top: 10%;
  left: 10%;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
}
.minBookGrid .minBoard.minBoardImg {
  object-fit: contain;
  background: none;
}
.minBookGrid .minBoard .boardBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.minBookGrid .minBoard .boardBox::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
                height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-sizing: border-box;
}
.minBookGrid .minBoard .gridCur::before {
  content: '';
  display: block;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 2px solid #FF48ED;
  box-sizing: border-box;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 4;
}
.minBookGrid .minBoard .boardBoxItem {
  width: 10%;
  height: 10%;
  position: absolute;
  z-index: 3;
}
.minBookGrid .minBoard .boardBoxItem::after {
  content: '';
  display: block;
  /*width: calc(100% - 2px);
                height:  calc(100% - 2px);*/
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #363636;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.minBookGrid .minBoard .boardBoxItem.saleStatus0 {
  background: #01a8ff;
}
.minBookGrid .minBoard .boardBoxItem.saleStatus1 {
  background: #ffbb00;
}
.minBookGrid .minBoard .boardBoxItem.saleStatus2 {
  background: #33CC66;
}
.gridItemTitle {
  width: 100%;
}
.gridItemTitle .gridItemName {
  line-height: 40px;
  word-wrap: break-word;
  font-weight: bold;
}
.gridItemEdit {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #F2F4F6 url(../../assets/images/apps/more-icon.png) no-repeat;
  background-position: center;
  text-indent: -9999px;
  transition: all 0.2s ease-in;
}
.gridItemEdit:hover {
  background: #E6EFFE url(../../assets/images/apps/more-icon-hover.png) no-repeat;
  background-position: center;
}
.gridItemCollect {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #F2F4F6 url(../../assets/images/menu/MyCollections-light-icon.png) no-repeat;
  background-position: center;
  text-indent: -9999px;
  transition: all 0.2s ease-in;
}
.gridItemCollect:hover,
.gridItemCollect.hasCollect {
  background: #E6EFFE url(../../assets/images/menu/MyCollections-hover-icon.png) no-repeat;
  background-position: center;
}
.gridItemDesc {
  line-height: 24px;
  width: 100%;
  max-height: 128px;
  overflow-y: auto;
}
font {
  font-weight: 400 !important;
}
.gridDescRow {
  height: 24px;
}
.gridDescRow span {
  white-space: nowrap;
}
.gridDescRow .rarityN {
  color: #6f797f;
}
.gridDescRow .rarityR {
  color: #48e28b;
}
.gridDescRow .raritySR {
  color: #377fff;
}
.gridDescRow .raritySS {
  color: #cc35f9;
}
.gridDescRow .raritySSS {
  color: #fd7f41;
}
.gridItemBtn {
  width: 100%;
  border-radius: 40px;
}
.robotTips {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
}
.robotTips .robotTipsClose {
  width: 22px;
  height: 22px;
  background: url('../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.robotTips .robotTipsClose:hover {
  background: url('../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.robotTips .robot {
  position: relative;
}
.robotTips .robot .robotImg {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
}
.robotTips .robotTipsMain {
  width: 100%;
  padding: 32px 0;
  position: relative;
  font-size: 21px;
}
.robotTips .robotTipsMain .tipsTitle {
  color: #FF78CB;
}
.robotTips .robotTipsMain .tipsCentent {
  white-space: pre-wrap;
  line-height: 36px;
  height: calc(36px * 3);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 15px;
}
.robotTips .robotTipsMain .tipsCentent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 8px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.robotTips .robotTipsMain .tipsCentent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #00A8FF;
}
.robotTips .robotTipsMain .tipsCentent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background: #EDEDED;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 28px;
}
.unShowRobotTips {
  width: 138px;
  height: 80px;
  border-radius: 0 40px 40px 0;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  bottom: 20%;
  display: flex;
  align-items: flex-end;
  transition: all 0.2s ease-in;
}
.unShowRobotTips .openRobotTips {
  height: 100%;
  text-indent: -9999px;
  width: 51px;
  background: url(../../assets/images/apps/arrow-robotTips.png) no-repeat;
  background-position: center;
  background-size: auto;
  transition: all 0.2s ease-in;
}
.unShowRobotTips:hover .openRobotTips {
  background-size: 90%;
}
/* 正常是xl */
/* lg */
@media (max-width: 1200px) {
  .robotTips .robotTipsMain {
    font-size: 14px;
  }
  .robotTips .robotTipsMain .tipsCentent {
    line-height: 24px;
    height: calc(24px * 3);
  }
}
/* md */
/* sm */
/* sx */
