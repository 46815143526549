/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 550px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.logTitle {
  font-size: 36px;
  padding-top: 65px;
  color: #363636;
}
.logMain {
  width: 83.33333333%;
  /* 选择登录 */
  /* 邮箱手机号登录 */
}
.logMain .logTips {
  padding: 50px 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.logMain .logChangBtn {
  min-height: 200px;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  transition: all 0.3s linear;
}
.logMain .logChangBtn:hover {
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  color: #fff;
}
.logMain .logUnder {
  border-top: 1px solid #E3E3E3;
  margin-top: 50px;
  padding: 42px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  text-align: center;
}
.logMain .logForm {
  width: 100%;
}
.logMain .logTex {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 20px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  margin-top: 20px;
  box-sizing: border-box;
}
.logMain .logBtn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.logMain .logForgotPad {
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
}
.logMain .fromTipsBox {
  height: 60px;
}
.logMain .fromTipsBox .fromTips {
  height: 20px;
  color: #FF0000;
  font-size: 14px;
  line-height: 20px;
}
.logMain .hasTips {
  border-color: #FF0000;
}
.logBtn:disabled {
  opacity: 0.2;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
@media (max-width: 768px) {
  .popUps .popUpsMode {
    min-height: inherit;
  }
  .popUps .popUpsMode .logTitle {
    font-size: 24px;
    padding-top: 35px;
  }
  .popUps .popUpsMode .logMain .logTips {
    padding-top: 30px;
    font-size: 12px;
  }
  .popUps .popUpsMode .logMain .logChangBtn {
    min-height: 120px;
    font-size: 16px;
  }
  .popUps .popUpsMode .logMain .logChangBtn .logBtnIcon {
    width: 50px;
    height: 50px;
  }
  .popUps .popUpsMode .logMain .logUnder {
    padding: 24px 0;
    font-size: 12px;
    margin-top: 30px;
  }
}
/* sx */
