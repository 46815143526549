/* light */
/* dark */
.brandBanner {
  width: 100%;
  height: 350px;
  background: #020001;
}
.bannerBtn {
  width: 208px;
  height: 54px;
  background: linear-gradient(90deg, #F8FFE3, #C6FAFF);
  border-radius: 27px;
  line-height: 54px;
  color: #0296FE;
  font-size: 21px;
}
.brandMain {
  position: relative;
}
.brandMain:hover::after {
  transition: all 0.2s ease-in;
  border: 2px solid #057BFC;
}
.brandMain:hover .brandItem {
  transition: all 0.2s ease-in;
  border-color: #01A8FF;
}
.brandMain:hover .brandItem .brandItemHeading img {
  transition: all 0.2s ease-in;
  top: -40px;
}
.brandMain::after {
  content: '';
  display: block;
  width: 100%;
  height: 361px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  position: absolute;
  top: 7px;
  left: 7px;
  box-sizing: border-box;
}
.brandMain .brandItem {
  width: 100%;
  height: 361px;
  box-sizing: border-box;
  padding: 17px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
.brandMain .brandItem .brandItemBanner {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}
.brandMain .brandItem .brandItemHeading {
  position: relative;
  text-align: center;
}
.brandMain .brandItem .brandItemHeading img {
  position: absolute;
  object-fit: cover;
  width: 50px;
  height: 50px;
  border: 3px solid #fff;
  box-sizing: border-box;
  border-radius: 50px;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -26.5px;
  left: 50%;
  margin-left: -26.5px;
}
.brandMain .brandItem .itemLeft {
  position: relative;
}
.brandMain .brandItem .itemLeft::after {
  content: '';
  display: block;
  width: 1px;
  height: 18px;
  background: #E1E1E1;
  position: absolute;
  right: -0.5px;
  top: -2px;
}
