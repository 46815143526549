/* light */
/* dark */
.billMain {
  width: 100%;
  min-height: 680px;
  margin: 65px 0 237px;
}
.billMain .edtailCardTitle {
  width: 100%;
  height: 76px;
  box-sizing: border-box;
  padding: 0 24px;
}
.billMain .edtailCardTitle h3 {
  font-size: 24px;
}
.billMain .edtailCardTitle img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 12px;
}
.tableCentent {
  width: 100%;
  height: 78px;
  margin: 0;
  font-size: 14px;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 2;
  top: 0;
  box-shadow: none;
}
.tableCentent:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  top: -5px;
}
.tableCentent:hover::before {
  content: none;
}
.tableCentent::before {
  content: '';
  display: block;
  width: calc(100% - 60px);
  height: 1px;
  background: #E8E8E8;
  position: absolute;
  top: 0;
}
.billStatsLeft {
  position: relative;
}
.billStatsLeft::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #E8E8E8;
}
.billStatsIcon {
  width: 24px;
  height: 24px;
}
.likeSortImg {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  object-fit: cover;
}
.ownList {
  box-sizing: border-box;
  padding: 20px 15px;
  border-bottom: 1px solid #D8D8D8;
}
.ownList:hover {
  background: #fff;
  border-radius: 10px;
}
.ownList .ownSort {
  width: 40px;
}
.ownList .ownIcon {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
}
.payBtn {
  padding: 6px 30px !important;
  border-radius: 30px !important;
  font-size: 14px !important;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 24px;
  line-height: 24px;
}
.orderNav {
  padding: 20px 0;
}
.orderNav li {
  font-size: 16px;
  margin: 0 20px;
  width: 80px;
  line-height: 32px;
  text-align: center;
  position: relative;
}
.orderNav li.cur {
  color: #D30000;
}
.orderNav li.cur::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: 2px;
  background-color: #D30000;
  bottom: 0;
}
.orderNfts {
  width: 120px;
  line-height: 24px;
}
