/* light */
/* dark */
@font-face {
  font-family: vp-quote;
  src: url(../font/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: vp-quote, -apple-system-font, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  width: 100%;
}
html,
body {
  min-width: 375px;
  margin: 0 auto;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
a:hover {
  color: #00A8FF;
}
button {
  cursor: pointer;
}
/* 公共样式 */
.flex {
  display: flex;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
/* flex */
.jc-center {
  justify-content: center;
}
.jc-space-between {
  justify-content: space-between;
}
.jc-flex-end {
  justify-content: flex-end;
}
.jc-space-around {
  justify-content: space-around;
}
.jc-flex-start {
  justify-content: flex-start;
}
.ai-center {
  align-items: center;
}
.ai-flex-end {
  align-items: flex-end;
}
.ai-flex-start {
  align-items: flex-start;
}
.fd-column {
  flex-direction: column;
}
.fd-row {
  flex-direction: row;
}
.fw-nowrap {
  flex-wrap: nowrap;
}
.fw-wrap {
  flex-wrap: wrap;
}
.ac-flex-start {
  align-content: flex-start;
}
.ac-flex-end {
  align-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
/*字体*/
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-26 {
  font-size: 26px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-42 {
  font-size: 42px;
}
.font-46 {
  font-size: 46px;
}
.font-48 {
  font-size: 48px;
}
.col-blue {
  color: #00A8FF;
}
.col-9f {
  color: #9F9F9F;
}
.col-333 {
  color: #333;
}
.col-666 {
  color: #666;
}
.col-999 {
  color: #999;
}
.col-fff {
  color: #fff;
}
.col-red {
  color: #FF0000;
}
.col-green {
  color: #00D710;
}
.col-CD {
  color: #CDCDCD;
}
.col-E6 {
  color: #E6E6E6;
}
.lh-20 {
  line-height: 20px;
}
.lh-24 {
  line-height: 24px;
}
.lh-30 {
  line-height: 30px;
}
.lh-32 {
  line-height: 32px;
}
.lh-50 {
  line-height: 50px;
}
/*margin padding*/
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-25 {
  margin-bottom: 25px;
}
.ml-25 {
  margin-left: 25px;
}
.mr-25 {
  margin-right: 25px;
}
.pt-25 {
  padding-top: 25px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pl-25 {
  padding-left: 25px;
}
.pr-25 {
  padding-right: 25px;
}
.mt-28 {
  margin-top: 28px;
}
.mb-28 {
  margin-bottom: 28px;
}
.ml-28 {
  margin-left: 28px;
}
.mr-28 {
  margin-right: 28px;
}
.pt-28 {
  padding-top: 28px;
}
.pb-28 {
  padding-bottom: 28px;
}
.pl-28 {
  padding-left: 28px;
}
.pr-28 {
  padding-right: 28px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mb-35 {
  margin-bottom: 35px;
}
.ml-35 {
  margin-left: 35px;
}
.mr-35 {
  margin-right: 35px;
}
.pt-35 {
  padding-top: 35px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pl-35 {
  padding-left: 35px;
}
.pr-35 {
  padding-right: 35px;
}
.mt-55 {
  margin-top: 55px;
}
.mb-55 {
  margin-bottom: 55px;
}
.ml-55 {
  margin-left: 55px;
}
.mr-55 {
  margin-right: 55px;
}
.pt-55 {
  padding-top: 55px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pl-55 {
  padding-left: 55px;
}
.pr-55 {
  padding-right: 55px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-45 {
  margin-bottom: 45px;
}
.ml-45 {
  margin-left: 45px;
}
.mr-45 {
  margin-right: 45px;
}
.pt-45 {
  padding-top: 45px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pl-45 {
  padding-left: 45px;
}
.pr-45 {
  padding-right: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-50 {
  margin-right: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-60 {
  margin-bottom: 60px;
}
.ml-60 {
  margin-left: 60px;
}
.mr-60 {
  margin-right: 60px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pl-60 {
  padding-left: 60px;
}
.pr-60 {
  padding-right: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mb-65 {
  margin-bottom: 65px;
}
.ml-65 {
  margin-left: 65px;
}
.mr-65 {
  margin-right: 65px;
}
.pt-65 {
  padding-top: 65px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pl-65 {
  padding-left: 65px;
}
.pr-65 {
  padding-right: 65px;
}
.mt-75 {
  margin-top: 75px;
}
.mb-75 {
  margin-bottom: 75px;
}
.ml-75 {
  margin-left: 75px;
}
.mr-75 {
  margin-right: 75px;
}
.pt-75 {
  padding-top: 75px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pl-75 {
  padding-left: 75px;
}
.pr-75 {
  padding-right: 75px;
}
.border-box {
  box-sizing: border-box;
}
/*按钮*/
.btn {
  border-radius: 10px;
  font-size: 18px;
  padding: 15px 30px;
  background: #fff;
  color: #00A8FF;
}
.blue-btn {
  background: #00A8FF;
  color: #fff;
}
.blue-btn:hover {
  background: #048bfd;
  color: #fff;
}
.white-btn {
  background: #fff;
  color: #00A8FF;
}
.white-btn:hover {
  background: #E6F1FC;
}
.gray-btn {
  background: #999;
  color: #fff;
}
.gray-btn:hover {
  background: #666;
}
/*间距*/
.space-8 {
  gap: 8px;
  align-items: center;
  display: inline-flex;
}
.space-16 {
  gap: 16px;
  align-items: center;
  display: inline-flex;
}
.space-20 {
  gap: 20px;
  align-items: center;
  display: inline-flex;
}
.space-24 {
  gap: 24px;
  align-items: center;
  display: inline-flex;
}
.space-40 {
  gap: 40px;
  align-items: center;
  display: inline-flex;
}
/*背景颜色*/
.bglight {
  background: #fff;
}
.bgdark {
  background: #000;
}
.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-break: break-all;
}
.ellipsis-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  word-break: break-all;
}
.ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.banner-anim-thumb {
  bottom: -40px !important;
}
.banner-anim-thumb-default span:active,
.banner-anim-thumb-default span.active {
  background: #000;
}
.banner-anim-thumb-default span {
  background-color: rgba(255, 255, 255, 0.9);
}
