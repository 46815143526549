/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 550px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.logTitle {
  font-size: 36px;
  padding-top: 65px;
  color: #363636;
}
.logMain {
  width: 83.33333333%;
  /* 选择登录 */
  /* 邮箱手机号登录 */
}
.logMain .logTips {
  padding: 50px 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.logMain .logChangBtn {
  min-height: 200px;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  transition: all 0.3s linear;
}
.logMain .logChangBtn:hover {
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  color: #fff;
}
.logMain .logUnder {
  border-top: 1px solid #E3E3E3;
  margin-top: 50px;
  padding: 42px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  text-align: center;
}
.logMain .logForm {
  width: 100%;
}
.logMain .logTex {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 20px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  margin-top: 20px;
  box-sizing: border-box;
}
.logMain .logBtn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.logMain .logForgotPad {
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
}
.logMain .fromTipsBox {
  height: 60px;
}
.logMain .fromTipsBox .fromTips {
  height: 20px;
  color: #FF0000;
  font-size: 14px;
  line-height: 20px;
}
.logMain .hasTips {
  border-color: #FF0000;
}
.logBtn:disabled {
  opacity: 0.2;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
@media (max-width: 768px) {
  .popUps .popUpsMode {
    min-height: inherit;
  }
  .popUps .popUpsMode .logTitle {
    font-size: 24px;
    padding-top: 35px;
  }
  .popUps .popUpsMode .logMain .logTips {
    padding-top: 30px;
    font-size: 12px;
  }
  .popUps .popUpsMode .logMain .logChangBtn {
    min-height: 120px;
    font-size: 16px;
  }
  .popUps .popUpsMode .logMain .logChangBtn .logBtnIcon {
    width: 50px;
    height: 50px;
  }
  .popUps .popUpsMode .logMain .logUnder {
    padding: 24px 0;
    font-size: 12px;
    margin-top: 30px;
  }
}
/* sx */
.warnTips {
  background-color: rgba(255, 158, 51, 0.1);
  color: #FF9E33;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  line-height: 20px;
  border-radius: 10px;
}
.cardBox,
.cardPicBox {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  border-radius: 10px;
  overflow: hidden;
}
.cardBox label span,
.cardPicBox label span {
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
.cardBox label .uploadImg,
.cardPicBox label .uploadImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  object-fit: cover;
}
.cardBox:hover .removeImg,
.cardPicBox:hover .removeImg {
  display: flex;
}
.cardBox .removeImg,
.cardPicBox .removeImg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.cardBox label::before,
.cardPicBox label::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cardBox label::before {
  background: url(../../../assets/images/login/card-bg.png) no-repeat;
  background-size: 100%;
}
.cardPicBox label::before {
  background: url(../../../assets/images/login/card-bg-pic.png) no-repeat;
  background-size: 100%;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
.warnTips {
  background-color: rgba(255, 158, 51, 0.1);
  color: #FF9E33;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  line-height: 20px;
  border-radius: 10px;
}
.cardBox,
.cardPicBox {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  border-radius: 10px;
  overflow: hidden;
}
.cardBox label span,
.cardPicBox label span {
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
.cardBox label .uploadImg,
.cardPicBox label .uploadImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  object-fit: cover;
}
.cardBox:hover .removeImg,
.cardPicBox:hover .removeImg {
  display: flex;
}
.cardBox .removeImg,
.cardPicBox .removeImg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.cardBox label::before,
.cardPicBox label::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cardBox label::before {
  background: url(../../../assets/images/login/card-bg.png) no-repeat;
  background-size: 100%;
}
.cardPicBox label::before {
  background: url(../../../assets/images/login/card-bg-pic.png) no-repeat;
  background-size: 100%;
}
.logBtn:disabled {
  opacity: 0.2;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
.modeBtnTop {
  border-top: 1px solid #E3E3E3;
}
.nickName {
  text-align: center;
  line-height: 24px;
}
.nickName > p {
  font-size: 24px;
}
.nickName > span {
  font-size: 14px;
  color: #666666;
}
.userAsset {
  box-shadow: 0px 0px 10px 0px rgba(34, 23, 20, 0.05);
  border-radius: 15px;
  background-color: #fff;
}
.topTab {
  border-bottom: 1px solid #D8D8D8;
  text-align: left;
  color: #9f9f9f;
}
.topTab > li {
  border-bottom: 4px solid transparent;
  width: 160px;
  display: inline-block;
  padding: 16px 0px;
  cursor: pointer;
}
.topTab .active {
  border-color: #01A8FF;
  color: #01A8FF;
}
.bottomTab {
  text-align: left;
  color: #000000;
}
.bottomTab > li {
  border-bottom: 2px solid transparent;
  width: 136px;
  display: inline-block;
  padding: 16px 8px;
  cursor: pointer;
  border-radius: 10px;
}
.bottomTab .active {
  background-color: #00A8FF;
  color: #FFFFFF;
}
.card {
  width: 94%;
  border-radius: 12px;
  border: 1px solid #D1D1D1;
  overflow: hidden;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.1);
  margin: 0 auto;
  margin-bottom: 16px;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
}
.card:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -5px;
}
.card .image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.card .childImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.card .nftName {
  font-size: 15px;
  font-weight: bold;
  padding: 6px 12px;
}
.card .cardTips {
  width: 100%;
  margin: 0 auto !important;
  padding: 9px 0 15px;
  text-align: center;
  display: flex;
  align-items: center;
}
.card .cardTips .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  height: 48px;
}
.card .cardTips .orginInfo {
  font-size: 14px;
  height: 22px;
}
.card .cardTips .orginInfo .orginInfoImg {
  width: 22px;
  height: 22px;
  border-radius: 22px;
}
.card .cardTips .orginInfo .orginInfoImg img {
  width: 22px;
  height: 22px;
}
.card .cardTips .orginInfo .orginInfoName {
  width: calc(100%-24px);
  box-sizing: border-box;
  padding-left: 7px;
  line-height: 22px;
}
.card .cardTips .orginInfo .orginInfoSymbol {
  text-align: right;
  font-size: 16px;
  line-height: 22px;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 24px;
}
.medal {
  width: 100%;
  max-height: 520px;
}
.medalUl {
  max-width: 1286px;
  margin: 0 auto;
  overflow: hidden;
  font-size: 12px;
  list-style-type: none;
  padding-bottom: 4.4%;
}
.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  width: 12.5%;
}
.hex:nth-child(15n+9) {
  /* first hexagon of even rows */
  margin-left: 6.25%;
  /* = width of .hex / 2  to indent even rows */
}
.hex::after {
  content: '';
  display: block;
  padding-bottom: 86.602%;
}
.hexIn {
  position: absolute;
  width: 90%;
  padding-bottom: 103.926%;
  /* =  width / sin(60) */
  margin: 0 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
}
.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}
.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  background-color: #FFF;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}
.linkHover {
  background-color: coral;
}
.top {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 30%;
}
.hex img {
  left: -100%;
  right: -100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}
.hex h1,
.hex p {
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
  background-color: rgba(0, 128, 128, 0.8);
  font-weight: 300;
  -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.3s ease-out;
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
}
.hex h1 {
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}
.hex h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}
.hex p {
  top: 50%;
  padding-bottom: 50%;
  -webkit-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.cardBottom > span {
  color: #999999;
  font-size: 14px;
}
.cardBottom > p {
  margin-top: 8px;
  height: 11px;
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.divider {
  position: relative;
}
.divider::after {
  content: '';
  display: block;
  background: #E1E1E1;
  width: 0.6px;
  height: 50%;
  top: 25%;
  right: 0;
  position: absolute;
  float: right;
}
.bottomContent {
  margin-top: 55px;
  background-color: #01A8FF;
}
.bottomText {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottomText > span {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 24px;
}
.bottomText > p {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 15px;
}
.readMore {
  background: url('../../../assets/images/profile/footer-ballbg.jpg') no-repeat;
  background-size: cover;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 24px;
  line-height: 24px;
}
.cardbtn {
  font-size: 12px;
  background-color: #01A8FF;
  color: #fff;
  padding: 4px 0;
  width: 60px;
  border-radius: 5px;
  box-sizing: border-box;
}
.cardbtn:hover {
  background: #048bfd;
}
.cardbtn.cardbtnw {
  background-color: #fff;
  color: #01A8FF;
  border: 1px solid #01A8FF;
}
.cardbtn.cardbtnw:hover {
  background: #048bfd;
}
.addMetaTitle {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  top: 20px;
  width: 100%;
  padding-bottom: 14px;
  text-align: center;
}
.addMetaInput {
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 4px;
  width: 100%;
  border: 1px solid #e5e8eb;
  padding: 8px;
  font-size: 16px;
  border-radius: 6px;
}
.addMetaInput:focus {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  outline: none;
}
.btn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.btn:disabled {
  opacity: 0.2;
}
.rarityNav {
  background: #F0F0F0;
  text-align: center;
  padding: 10px 0;
  line-height: 20px;
  border-radius: 8px;
}
.rarityNav.rarityNavSSS {
  color: #FF7820;
}
.rarityNav.rarityNavSS {
  color: #CB29D9;
}
.rarityNav.rarityNavSR {
  color: #0963FB;
}
.rarityNav.rarityNavR {
  color: #1BCF51;
}
.rarityNav.rarityNavN {
  color: #666;
}
.rarityNav.rarityNavCur {
  background-color: #00A8FF !important;
  color: #fff !important;
}
.tipsSize {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  height: 32px;
  line-height: 32px;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 0 10px;
}
/* lg */
/* md */
/* sm */
/* sx */
.mask {
  position: absolute;
  inset: 0px;
  transition: opacity 0.1s ease-in 0s;
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}
.album {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background: url('../../../assets/images/img_xiangkuang.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.album .albumImg {
  width: 90%;
  height: 90%;
  position: absolute;
  left: 5%;
  top: 5%;
  box-sizing: border-box;
  border: 10px solid #ffffff;
}
.albumTips {
  border-bottom: 1px solid #E8E8E8;
}
.albumDesc {
  width: 100%;
  height: auto;
  max-height: 200px;
  background-color: #F5F5F5;
  border-radius: 6px;
  margin: 25px auto;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
.billMain {
  width: 100%;
  min-height: 680px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(34, 23, 20, 0.05);
}
.billMain .edtailCardTitle {
  width: 100%;
  height: 76px;
  border-bottom: 2px solid #D8D8D8;
  box-sizing: border-box;
  padding: 0 24px;
}
.billMain .edtailCardTitle h3 {
  font-size: 24px;
}
.billMain .edtailCardTitle img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 12px;
}
.tableCentent {
  width: 100%;
  height: 78px;
  margin: 0;
  font-size: 14px;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 2;
  top: 0;
  box-shadow: none;
}
.tableCentent:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  top: -5px;
}
.tableCentent:hover::before {
  content: none;
}
.tableCentent::before {
  content: '';
  display: block;
  width: calc(100% - 60px);
  height: 1px;
  background: #E8E8E8;
  position: absolute;
  top: 0;
}
.billStatsLeft {
  position: relative;
}
.billStatsLeft::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #E8E8E8;
}
.billStatsIcon {
  width: 24px;
  height: 24px;
}
.billTop {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(34, 23, 20, 0.05);
}
.billTop .userAvatar {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}
.billTop .billTopLeft {
  padding: 32px;
}
.billTop .billTopRight {
  padding: 32px;
  border-left: 1px solid #E1E1E1;
}
.medalBox {
  box-sizing: border-box;
  background: #F2F4F6;
  height: 110px;
  border-radius: 14px;
}
.medalBox img {
  width: 40px;
  height: 58px;
}
.levelTitle {
  border: 1px solid #01A8FF;
  height: 28px;
  line-height: 28px;
  border-radius: 28px;
  display: inline-block;
  padding: 0 15px;
}
.levelBar {
  width: 100%;
  height: 14px;
  border-radius: 14px;
  background: #EEEEEE;
  position: relative;
}
.levelBar .levelBarLine {
  width: 0;
  height: 14px;
  border-radius: 7px;
  background: #01A8FF;
}
.levelBar .levelBarTips {
  position: absolute;
  z-index: 1;
  top: -11px;
  left: 0;
  width: 36px;
  height: 36px;
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 1px 12px 0px rgba(28, 200, 255, 0.7);
  border-radius: 50%;
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 36px;
}
.curentMain {
  box-sizing: border-box;
  padding: 30px;
}
.tipsImg {
  width: 90px;
  height: 90px;
  border: 1px solid #E1E1E1;
  border-radius: 90px;
}
.receiveBox {
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 22px 0 22px 22px;
  position: relative;
  height: 114px;
}
.receiveBox .receiveBoxTips {
  height: 100%;
  position: relative;
}
.receiveBox .receiveBoxTips .receiveNum {
  position: absolute;
  bottom: 0;
}
.receiveBox img {
  right: 0;
  height: 70px;
}
.receiveBtn {
  width: 340px;
  height: 72px;
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  border-radius: 36px;
  position: relative;
}
.receiveBtn img {
  position: absolute;
  left: -16px;
  top: -14px;
  width: 100px;
  height: 100px;
}
.receiveBtn .receiveBtnTips {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 60px;
  text-align: center;
}
.receiveBtn .receiveBtnTips .h4 {
  font-weight: normal;
}
.receiveBtn .receiveBtnTips .receiveBtnMultiple {
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  display: inline-block;
  box-sizing: border-box;
  padding: 3px 5px;
  margin: 0 5px;
}
.daoTips {
  background: url(../../../assets/images/bill/convert/bg.png) no-repeat;
  background-size: 100%;
  background-position: center;
  border-radius: 10px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 0 20px;
}
.daoMedalMain:hover::before,
.daoMedalMain:hover .daoMedal {
  transition: all 0.2s ease-in;
  border-color: #01A8FF;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(209, 209, 209, 0.3);
}
.daoMedalMain::before {
  content: '';
  position: absolute;
  width: calc(100% - 32px);
  background-color: #fff;
  height: 100%;
  top: 5px;
  left: 16px;
  border-radius: 10px;
  border: 1px solid #E1E1E1;
}
.daoMedal {
  width: 100%;
  min-height: 379px;
  background: #FFF;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  position: relative;
  z-index: 2;
}
.daoMedal.daoGMedal {
  background: #FFF url(../../../assets/images/bill/convert/GBG.png) no-repeat;
  background-position: top center;
  background-size: 100%;
}
.daoMedal.daoSMedal {
  background: #FFF url(../../../assets/images/bill/convert/SBG.png) no-repeat;
  background-position: top center;
  background-size: 100%;
}
.daoMedal.daoCMedal {
  background: #FFF url(../../../assets/images/bill/convert/CBG.png) no-repeat;
  background-position: top center;
  background-size: 100%;
}
.daoMedal::before {
  width: ;
}
.daoMedal .tipsbox {
  position: relative;
}
.daoMedal .tipsbox::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -16px;
  width: 1px;
  height: 32px;
  background: #E1E1E1;
}
.daoMedal .tipsbox:last-of-type::after {
  content: none;
}
.medalBtn {
  width: 260px;
  height: 54px;
  background: #01A8FF;
  border-radius: 27px;
  text-align: center;
  color: #fff;
  line-height: 54px;
  font-size: 18px;
  position: relative;
}
.medalBtn:disabled {
  opacity: 0.5;
}
.medalBtn.noMedalBtn {
  background: #999999;
}
.medalBtn .medalBtnDiscount {
  background: url(../../../assets/images/bill/convert/discountbg-icon.png) no-repeat;
  width: 70px;
  height: 79px;
  box-sizing: border-box;
  padding: 0 8px 12px 0;
  line-height: 24px;
  position: absolute;
  top: -8px;
}
.receiveBillList {
  min-height: 320px;
}
.timeBox {
  width: 48px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  font-size: 24px;
  color: #333;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(34, 23, 20, 0.05);
  margin: 0 10px;
}
.cardbtn {
  font-size: 12px;
  background-color: #01A8FF;
  color: #fff;
  padding: 4px 0;
  width: 60px;
  border-radius: 5px;
  box-sizing: border-box;
}
.cardbtn:hover {
  background: #048bfd;
}
.noReceivetFn {
  filter: grayscale(100%);
}
/* 正常是xl */
/* lg */
/* md */
@media (max-width: 992px) {
  .billTop .billTopRight {
    border-left: 0;
    border-top: 1px solid #E1E1E1;
  }
}
/* sm */
@media (max-width: 768px) {
  .billLeftBox {
    margin-bottom: 20px;
  }
}
/* sx */
.modeBtnTop {
  border-top: 1px solid #E3E3E3;
}
.medalBtn {
  width: 100%;
}
.medalBtn:disabled {
  opacity: 0.2;
}
.payIcon {
  width: 20px;
  height: 20px;
}
.logTex {
  margin-top: 0px !important;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
.medalCancelBtn {
  background-color: #e1e1e1;
}
.medalCancelBtn:hover {
  background-color: #999;
}
.logTitleMain {
  width: 100%;
}
.timeStyle {
  display: inline-block;
  width: 50px;
}
.openImg {
  object-fit: cover;
  border-radius: 10px;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
