/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.popUps .popUpsMode .modeTitle {
  font-size: 24px;
  padding: 15px;
  color: #363636;
}
.popUps .popUpsMode .modeMain {
  width: 100%;
  height: calc(100%-100px);
  margin: 0;
  box-sizing: border-box;
}
.popUps .popUpsMode .modeMain .createBtn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.popUps .popUpsMode .modeMain .createBtn:disabled {
  opacity: 0.2;
}
.popUps .popUpsMode .modeMain .required.field > label:after {
  margin: -4px 0em 0em 4px;
  content: '*';
  color: #DB2828;
  display: inline-block;
  vertical-align: top;
}
.popUps .popUpsMode .modeMain .field > label {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
  width: 80px;
  height: 38px;
  line-height: 38px;
}
.popUps .popUpsMode .modeMain .field .fieldSelect {
  width: 100%;
}
.popUps .popUpsMode .modeMain .field .fieldSelect > div {
  border-color: rgba(34, 36, 38, 0.15);
  border-radius: 8px;
}
.popUps .popUpsMode .modeMain .field > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #707a83;
}
.popUps .popUpsMode .modeMain .field:last-child {
  margin-bottom: 0em;
}
.popUps .popUpsMode .modeMain .fieldTree {
  width: 100%;
  height: 182px;
  box-sizing: border-box;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  overflow-y: auto ;
}
.popUps .popUpsMode .modeMain .fieldTree .fieldItem:hover .fieldTreeItemEdit {
  display: flex;
}
.popUps .popUpsMode .modeMain .fieldTree .fieldItem.fieldCurItem {
  background-color: #e3e3e3;
  color: #fff;
}
.popUps .popUpsMode .modeMain .fieldTree .fieldItem .fieldTreeItemEdit {
  display: none;
}
.popUps .popUpsMode .modeMain .fieldTree .fieldTreeTitle::before {
  content: '';
  display: inline-block;
  border: 5px solid transparent;
  border-top: 5px solid #636466;
  position: relative;
  top: 3px;
}
.popUps .popUpsMode .modeMain .fieldTree .fieldTreeItem {
  line-height: 32px;
}
.popUps .popUpsMode .modeMain .fieldTree .folderText {
  width: auto;
  padding: 0 8px;
  border-width: 1px;
  max-width: 60%;
}
.popUps .popUpsMode .modeMain .btnMore {
  font-size: 14px;
  color: #01A8FF;
  background: #fff;
  padding: 8px 16px;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
}
.popUps .popUpsMode .modeMain .btnSave {
  font-size: 14px;
  color: #fff;
  background: #01A8FF;
  padding: 8px 20px;
  border-radius: 20px;
}
.popUps .popUpsMode .modeMain .btnCancel {
  font-size: 14px;
  color: #01A8FF;
  background: #E6EFFE;
  padding: 8px 20px;
  border-radius: 20px;
}
.popUps .popUpsMode .modeMain textarea {
  width: 100%;
  margin: 0em;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 0px transparent inset;
  box-shadow: 0px 0px 0px 0px transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  line-height: 18px;
  resize: vertical;
  vertical-align: top;
}
.popUps .popUpsMode .modeMain textarea:not([rows]) {
  height: 120px;
  min-height: 64px;
  max-height: 280px;
}
.popUps .popUpsMode .modeMain input[type="text"] {
  width: 100%;
  vertical-align: top;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 24px;
  padding: 6px 14px;
  font-size: 14px;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
@media (max-width: 768px) {
  .popUps .popUpsMode {
    min-height: inherit;
  }
  .popUps .popUpsMode .modeTitle {
    font-size: 18px;
  }
  .popUps .popUpsMode .logMain .logTips {
    padding-top: 30px;
    font-size: 12px;
  }
  .popUps .popUpsMode .logMain .logChangBtn {
    min-height: 120px;
    font-size: 16px;
  }
  .popUps .popUpsMode .logMain .logChangBtn .logBtnIcon {
    width: 50px;
    height: 50px;
  }
  .popUps .popUpsMode .logMain .logUnder {
    padding: 24px 0;
    font-size: 12px;
    margin-top: 30px;
  }
}
/* sx */
