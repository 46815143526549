/* light */
/* dark */
.settings {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 24px 0px;
  margin: 0 auto;
}
.settings .title {
  font-weight: 600;
  font-size: 40px;
  color: #04111d;
  margin-bottom: 16px;
  margin-top: 28px;
}
.settings .creFrom {
  position: relative;
  max-width: 100%;
}
.settings .creFrom > p {
  font-weight: 500;
  font-size: 12px;
  color: #707a83;
  margin: 24px 0 24px 0;
}
.settings .creFrom .required.field > label:after {
  margin: -4px 0em 0em 4px;
  content: '*';
  color: #DB2828;
  display: inline-block;
  vertical-align: top;
}
.settings .creFrom .field {
  clear: both;
  margin: 0em 0em 1em;
}
.settings .creFrom .field > label {
  display: block;
  margin: 0em 0em 12px 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}
.settings .creFrom .field > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #707a83;
  margin: 8px 0 8px 0;
}
.settings .creFrom .field:last-child {
  margin-bottom: 0em;
}
.settings .creFrom input[type="text"] {
  width: 100%;
  vertical-align: top;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}
.metaDataModel {
  padding-top: 16px;
  border-bottom: 1px solid #e5e8eb;
  padding-bottom: 16px;
}
.metaDataModel .general {
  display: flex;
  justify-content: space-between;
}
.metaDataModel .general > img {
  width: 24px;
  height: 24px;
}
.metaDataModel .metaDataType {
  margin: 0;
  font-weight: 600;
  font-size: 16;
  display: block;
}
.avatarModel {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  border: 3px dashed #cccccc;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 160px;
  width: 160px;
  margin-bottom: 36px;
}
.avatarModel > img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.bannerModel {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border-radius: 24px;
  border: 3px dashed #cccccc;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 220px;
  width: 98%;
  margin-bottom: 36px;
}
.bannerModel > img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.btn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #2081e2;
  color: #2081e2;
}
.upDiv:hover {
  position: 'absolute';
  inset: 4;
  z-index: 70;
  border-radius: 10;
  background-color: rgba(0, 0, 0, 0.25);
}
.createBtn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.createBtn:disabled {
  opacity: 0.2;
}
.buttonIcon {
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.iconImg {
  width: 24px;
  height: 24px;
}
.disInput {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(138, 147, 155, 0.06);
  border-radius: 10px;
  border: 1px solid #e5e8eb;
  display: flex;
  height: 48px;
  padding: 0px 12px;
}
.disInput .content {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.disInput .content .address {
  white-space: nowrap;
  margin-right: 16px;
}
.disInput .content .copyBtn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 100%;
  font-family: inherit;
  border: 0px;
  padding: 0px;
  background: inherit;
}
