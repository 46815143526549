/* light */
/* dark */
.bottomContent {
  margin-top: 55px;
  background-color: #01A8FF;
}
.bottomText {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bottomText > span {
  font-weight: bold;
  color: #FFFFFF;
  font-size: 24px;
}
.bottomText > p {
  font-size: 16px;
  color: #FFFFFF;
  margin-top: 15px;
}
.readMore {
  background: url('../../../assets/images/profile/footer-ballbg.jpg') no-repeat;
  background-size: cover;
}
