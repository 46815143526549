/* light */
/* dark */
.bannerWrap {
  position: relative;
  height: 360px;
  margin-top: 106px;
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.txtWrap {
  position: absolute;
  left: 45px;
  top: 130px;
}
.txtO {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42px;
}
.txtT {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 25px;
  margin-top: 5px;
}
.title {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #363636;
  line-height: 50px;
  margin-top: 52px;
}
.content {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #363636;
  line-height: 22px;
  margin-top: 5px;
  padding-bottom: 188px;
  white-space: pre-wrap;
  /*css-3*/
  white-space: -moz-pre-wrap;
  /*Mozilla,since1999*/
  white-space: -pre-wrap;
  /*Opera4-6*/
  white-space: -o-pre-wrap;
  /*Opera7*/
  word-wrap: break-word;
  /*InternetExplorer5.5+*/
}
