/* light */
/* dark */
.creContent {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding: 24px 0px;
  margin: 0 auto;
}
.creContent .creTitle {
  font-weight: 600;
  font-size: 40px;
  color: #04111d;
  margin-bottom: 16px;
  margin-top: 28px;
}
.creContent .creFrom {
  position: relative;
  max-width: 100%;
}
.creContent .creFrom > p {
  font-weight: 500;
  font-size: 12px;
  color: #707a83;
  margin: 24px 0 24px 0;
}
.creContent .creFrom .required.field > label:after {
  margin: -4px 0em 0em 4px;
  content: '*';
  color: #DB2828;
  display: inline-block;
  vertical-align: top;
}
.creContent .creFrom .field {
  clear: both;
  margin: 0em 0em 1em;
}
.creContent .creFrom .field > label {
  display: block;
  margin: 0em 0em 12px 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}
.creContent .creFrom .field > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #707a83;
  margin: 8px 0 8px 0;
}
.creContent .creFrom .field:last-child {
  margin-bottom: 0em;
}
.creContent .creFrom textarea {
  width: 100%;
  margin: 0em;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 0px transparent inset;
  box-shadow: 0px 0px 0px 0px transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  line-height: 18px;
  resize: vertical;
  vertical-align: top;
}
.creContent .creFrom textarea:not([rows]) {
  height: 120px;
  min-height: 64px;
  max-height: 280px;
}
.creContent .creFrom input[type="text"] {
  width: 100%;
  vertical-align: top;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 24px;
  padding: 6px 14px;
  font-size: 14px;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}
.metaDataModel {
  padding-top: 16px;
  border-bottom: 1px solid #e5e8eb;
  padding-bottom: 16px;
}
.metaDataModel .general {
  display: flex;
  justify-content: space-between;
}
.metaDataModel .general > img {
  width: 24px;
  height: 24px;
}
.metaDataModel .metaDataType {
  margin: 0;
  font-weight: 600;
  font-size: 16;
  display: block;
}
.uploadModel {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: 257px;
  width: 350px;
}
.uploadModel > img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.uploadModel > video {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.addButton {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #2081e2;
  color: #2081e2;
}
.addButton .buttonIcon {
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.iconImg {
  width: 24px;
  height: 24px;
}
.popClose1 {
  width: 36px;
  height: 36px;
  background: url('../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.btn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #2081e2;
  color: #2081e2;
}
.upDiv:hover {
  position: 'absolute';
  inset: 4;
  z-index: 70;
  border-radius: 10;
  background-color: rgba(0, 0, 0, 0.25);
}
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 240px;
  max-height: 680px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.popUps .popUpsMode .logTitle {
  font-size: 36px;
  padding-top: 65px;
}
.popUps .popUpsMode .logMain {
  width: 83.33333333%;
  /* 选择登录 */
  /* 邮箱手机号登录 */
}
.popUps .popUpsMode .logMain .logTips {
  padding: 50px 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.popUps .popUpsMode .logMain .logChangBtn {
  min-height: 200px;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  transition: all 0.3s linear;
}
.popUps .popUpsMode .logMain .logChangBtn:hover {
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  color: #fff;
}
.popUps .popUpsMode .logMain .logUnder {
  border-top: 1px solid #19618D;
  margin-top: 50px;
  padding: 42px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  text-align: center;
}
.popUps .popUpsMode .logMain .logForm {
  width: 100%;
}
.popUps .popUpsMode .logMain .logTex {
  width: 100%;
  height: 48px;
  font-size: 18px;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  margin-top: 20px;
  box-sizing: border-box;
}
.popUps .popUpsMode .logMain .logBtn {
  width: 100%;
  height: 48px;
  font-size: 24px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.popUps .popUpsMode .logMain .logForgotPad {
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
}
.popUps .popUpsMode .logMain .fromTipsBox {
  height: 60px;
}
.popUps .popUpsMode .logMain .fromTipsBox .fromTips {
  height: 20px;
  color: #FF0000;
  font-size: 14px;
  line-height: 20px;
}
.popUps .popUpsMode .logMain .hasTips {
  border-color: #FF0000;
}
.addMetaTitle {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  top: 20px;
  width: 100%;
  border-bottom: 1px solid #e5e8eb;
  padding-bottom: 14px;
  text-align: center;
}
.addMetaContent {
  width: 100%;
  min-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 68px;
}
.addMetaRow {
  width: 96%;
  margin: 0 auto;
}
.addMetaDesc {
  flex-basis: 100%;
  max-width: 100%;
  line-height: 24px;
  margin-bottom: 16px;
}
.addMetaColName {
  flex-basis: 45%;
  max-width: 45%;
  margin-bottom: 16px;
}
.clearBtn {
  flex-basis: 10%;
  max-width: 10%;
  margin-bottom: 16px;
}
.addMetaInput {
  box-sizing: border-box;
  height: 36px;
  margin-bottom: 16px;
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
}
.addMetaInput:focus {
  outline: thin solid #2081e2;
}
.propItem {
  background-color: rgba(21, 178, 229, 0.06);
  border-radius: 6px;
  border: 1px solid #15b2e5;
  padding: 10px;
  text-align: center;
  width: 150px;
  margin: 5px;
}
.propItem .traitType {
  color: #15b2e5;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.propItem .value {
  color: #353840;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statsItem {
  border-radius: 10px;
  border: 1px solid #e5e8eb;
  padding: 12px;
  max-width: 428px;
  margin-top: 8px;
}
.statsItem .numTrait {
  display: flex;
  font-weight: 500;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.statsItem .numTrait .traitType {
  color: #707a83;
}
.statsItem .numTrait .value {
  color: #707a83;
  min-width: fit-content;
  padding-left: 4px;
}
.statsItem .bar {
  background-color: #fbfdff;
  border: 1px solid #e5e8eb;
  border-radius: 14px;
  height: 14px;
  margin-top: 4px;
  overflow: hidden;
}
.statsItem .bar .barFill {
  background-color: #2081e2;
  height: 100%;
}
.createBtn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.createBtn:disabled {
  opacity: 0.2;
}
