/* light */
/* dark */
.toolTip1 {
  border: none;
  border-radius: 10px !important;
  padding: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;
  opacity: 1 !important;
}
.shareUl {
  border: none;
  border-radius: 10px;
  margin: 0px;
  width: 100%;
}
.shareLi:not(:last-child) {
  border-bottom: 1px solid #e5e8eb;
}
.liCont {
  padding: 16px;
  color: #04111d;
}
.liCont img {
  margin-right: 16px;
  width: 24px;
  height: 24px;
}
.liCont span {
  font-weight: 600;
  font-size: 14px;
}
.liCont:hover {
  transition: all 0.2s ease 0s;
  background-color: #fbfdff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #00A8FF;
}
