/* light */
/* dark */
.logoModel {
  position: relative;
  padding: 4px;
  cursor: pointer;
  border: 3px dashed #cccccc;
  margin-bottom: 36px;
}
.logoModel > input {
  display: none;
}
.placeHolder {
  width: 64px;
  height: 64px;
}
.uploadImg {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: inherit;
}
