/* light */
/* dark */
.achvContent {
  min-height: 500px;
  max-width: 1500px;
  margin: 0 auto;
  border-radius: 20px;
}
/* lg */
@media (max-width: 1200px) {
  .exploreItem {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
}
/* md */
@media (max-width: 992px) {
  .exploreItem {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
}
/* sm */
@media (max-width: 768px) {
  .exploreItem {
    flex-basis: 50%;
    max-width: 50%;
  }
}
/* sx */
@media (max-width: 576px) {
  .exploreItem {
    flex-basis: 100%;
    max-width: 100%;
  }
}
