/* light */
/* dark */
.footerBody {
  width: 100%;
  min-height: 554px;
  background-color: #2E2E37;
}
.footerBody .footerMain {
  padding: 90px 0 0;
}
.footerBody .footerMain .left {
  position: relative;
  box-sizing: border-box;
  padding-right: 40px;
}
.footerBody .footerMain .left .leftCentent {
  margin: 40px 0;
  font-size: 14px;
  line-height: 24px;
}
.footerBody .footerMain .left::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -116px;
  height: 232px;
  width: 1px;
  background-color: #E6E6E6;
  opacity: 0.21;
}
.footerBody .footerMain .right {
  flex: 1;
  /* padding-left: 216px;*/
  color: #fff;
}
.footerBody .footerMain .right .rightTitle {
  color: #fff;
  font-size: 18px;
}
.footerBody .footerMain .right .lineItem {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 30px 0;
  padding-left: 0;
}
.footerBody .footerMain .right .lineItem .lineSubItem {
  padding-left: 0 !important;
}
.footerBody .footerContainer {
  /* 
    position: absolute;
    bottom: 0;
    */
  display: flex;
  align-items: center;
  width: 100%;
  height: 84px;
}
.footerBody .footerContainer .footerSubMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
}
.colWhite {
  color: #fff;
  font-size: 14px;
}
.rightCodeBox {
  width: 66px;
  position: fixed;
  right: 30px;
  bottom: 30%;
  z-index: 99;
}
.rightCodeBox .codeBox {
  height: 264px;
  background-color: #fff;
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.14);
}
.rightCodeBox .codeBox .boxMain {
  width: 66px;
  height: 66px;
  box-sizing: border-box;
  font-size: 16px;
  color: #0583FD;
  text-align: center;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
  position: relative;
}
.rightCodeBox .codeBox .boxMain:last-of-type {
  border-bottom: none;
}
.rightCodeBox .codeBox .boxMain:hover {
  background-color: #0583FD;
  color: #fff;
}
.rightCodeBox .codeBox .boxMain:hover .showBoxCentent {
  display: block;
}
.rightCodeBox .codeBox .boxMain .showBoxCentent {
  display: none;
  position: absolute;
  width: 200px;
  height: 215px;
  background: #FFFFFF;
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.14);
  right: 90px;
  bottom: 0;
  text-align: center;
}
.rightCodeBox .codeBox .boxMain .showBoxCentent img {
  width: 150px;
  height: 150px;
  margin-top: 20px;
}
.rightCodeBox .codeBox .boxMain .showBoxCentent::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 14px solid #fff;
  border-bottom: 7px solid transparent;
  position: absolute;
  right: -14px;
  bottom: 14px;
}
.returnTop {
  position: fixed;
  right: 30px;
  bottom: calc(30% - 86px);
  z-index: 99;
  width: 66px;
  height: 66px;
  background: #fff url(../../assets/icon/return-top-icon.png) no-repeat;
  background-position: center;
  text-indent: -99999px;
  margin-top: 20px;
  border: 3px solid #0583FD;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.14);
}
.returnTop:hover {
  background: #0583FD url(../../assets/icon/return-top-hover-icon.png) no-repeat;
  background-position: center;
}
.inline-block {
  display: inline-block;
}
