/* light */
/* dark */
.edtailMain {
  margin-top: 37px;
  margin-bottom: 237px;
}
.detailImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.detailImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.resultImg {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 200px;
  border-radius: 10px;
}
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 280px;
  max-height: 640px;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  border-radius: 20px;
}
.addMetaTitle {
  position: relative;
  font-size: 24px;
  line-height: 24px;
  top: 20px;
  width: 100%;
  border-bottom: 1px solid #e5e8eb;
  padding-bottom: 14px;
  text-align: center;
}
.addMetaTitle h4 {
  text-align: center;
  word-break: break-word;
  padding: 0px 24px;
}
.addMetaInput {
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 4px;
  width: 100%;
  border: 1px solid #e5e8eb;
  padding: 8px;
  font-size: 16px;
  border-radius: 6px;
}
.addMetaInput:focus {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  outline: none;
}
.modalCtn {
  width: 94%;
  margin: 16px 0px;
}
.modalCtn p {
  font-weight: 400;
  font-size: 16px;
  margin: 12px 0px;
  color: #707a83;
  text-align: center;
  line-height: 24px;
}
.bottomBtn {
  display: flex;
  align-content: center;
  height: 50px;
  padding: 12px 0px;
  width: 100%;
  border-top: 1px solid #e5e8eb;
  bottom: 0px;
}
.btn {
  margin: 0px 4px;
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #e5e8eb;
  color: #707a83;
  text-decoration: none;
  cursor: pointer;
}
.btn:hover {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
}
.edtailCentent {
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
}
.edtailCentent .topBtn {
  padding: 0 10px;
  text-align: center;
  height: 42px;
}
.edtailCentent .topBtn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.edtailCentent .topBtn p {
  font-size: 12px;
  color: #9F9F9F;
  padding-top: 5px;
}
.edtailCentent .counts {
  width: 20%;
  font-size: 14px;
  color: #9F9F9F;
}
.edtailCentent .counts img {
  margin-right: 7px;
}
.edtailCentent .detailDesc {
  font-size: 14px;
  color: #9F9F9F;
  line-height: 28px;
}
.edtailCentent .detailData {
  width: 20%;
}
.edtailCentent .detailData p {
  color: #9F9F9F;
  font-size: 14px;
}
.edtailCentent .detailData h3 {
  font-size: 18px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btnImg {
  animation: rotation 1s linear infinite;
}
.edtailCard {
  border-radius: 8px;
}
.edtailCard .edtailCardTitle {
  width: 100%;
  height: 76px;
  border-bottom: 2px solid #D8D8D8;
  box-sizing: border-box;
  padding: 0 24px;
  z-index: 20;
  position: relative;
}
.edtailCard .edtailCardTitle h3 {
  font-size: 24px;
}
.edtailCard .edtailCardTitle img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 12px;
}
.edtailCard .edtailCardCentent {
  box-sizing: border-box;
  padding: 24px;
}
.edtailCard .edtailCardCentent .edtailCardBox {
  width: 100%;
  padding: 15px 0;
  background-color: rgba(21, 178, 229, 0.06);
  border: 1px solid #15b2e5;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 16px;
  color: #000;
}
.edtailCard .edtailCardCentent .edtailCardBox p {
  font-size: 12px;
}
.edtailCard .edtailCardCentent .edtailCardBox h3 {
  font-size: 14px;
}
.edtailCard .edtailCardCentent .edtailCardDesc {
  font-size: 14px;
  color: #9F9F9F;
  line-height: 28px;
  font-weight: normal;
  position: relative;
}
.edtailCard .edtailCardCentent .edtailCardDesc .userAvatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.edtailCard .edtailCardCentent .edtailCardDesc .edtailBtn {
  padding: 5px 25px;
  font-size: 14px;
}
.edtailCard .edtailCardCentent .detailsCardTips {
  padding: 0 0 30px;
  font-size: 14px;
}
.edtailCard .edtailCardCentent .detailsCardTips:last-child {
  padding: 0;
}
.edtailCard .edtailCardCentent .detailsCardTips .detailsCardTipsTitle {
  width: 40%;
}
.edtailCard .userIcon {
  width: 36px;
  height: 36px;
}
.tableCentent {
  width: 100%;
  height: 68px;
  margin: 0;
  font-size: 14px;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 2;
  top: 0;
  box-shadow: none;
}
.tableCentent:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  top: -5px;
}
.actionImg {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.actionTitle {
  padding-left: 0;
}
