/* light */
/* dark */
.warnTips {
  background-color: rgba(255, 158, 51, 0.1);
  color: #FF9E33;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  line-height: 20px;
  border-radius: 10px;
}
.cardBox,
.cardPicBox {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  border-radius: 10px;
  overflow: hidden;
}
.cardBox label span,
.cardPicBox label span {
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
.cardBox label .uploadImg,
.cardPicBox label .uploadImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  object-fit: cover;
}
.cardBox:hover .removeImg,
.cardPicBox:hover .removeImg {
  display: flex;
}
.cardBox .removeImg,
.cardPicBox .removeImg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.cardBox label::before,
.cardPicBox label::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cardBox label::before {
  background: url(../../../../assets/images/login/card-bg.png) no-repeat;
  background-size: 100%;
}
.cardPicBox label::before {
  background: url(../../../../assets/images/login/card-bg-pic.png) no-repeat;
  background-size: 100%;
}
.logTitle {
  font-size: 36px;
  padding-top: 65px;
  color: #363636;
}
.logTex {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 20px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  box-sizing: border-box;
}
.logBtn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
