/* light */
/* dark */
.modeWebMain {
  padding: 70px 0 75px;
  margin: 0;
  width: 100%;
  border-radius: 16px;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
}
.modeWebMain:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -5px;
}
.modeWebMain .modeWebBox {
  position: relative;
}
.modeWebMain .modeWebBox::after {
  position: absolute;
  top: 91px;
  right: -23px;
  display: block;
  width: 47px;
  height: 15px;
  background: url('../../assets/images/img/arrow.png');
  content: '';
}
.modeWebMain .modeWebBox:last-child::after {
  content: none;
}
.modeWebMain .modeWebBox h3 {
  font-size: 36px;
  font-weight: 500;
  margin: 72px auto 20px;
}
.modeWebMain .modeWebBox p {
  font-size: 24px;
  font-weight: 500;
  color: #838383;
}
.modeMain {
  padding: 78px 48px;
  margin: 0;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
}
.modeMain:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -5px;
}
.modeMain img {
  width: 100%;
}
.modeMain .modeMainCenter {
  line-height: 20px;
}
.modeMain .modeMainBtn {
  width: 237px;
  margin: 30px auto;
}
.modeApiMain {
  padding: 57px 43px 86px;
  margin: 0 0 220px 0;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
}
.modeApiMain:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -5px;
}
.modeApiMain .title {
  font-size: 36px;
  font-weight: 500;
  padding-bottom: 53px;
}
.modeApiMain .apimode h3 {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 45px;
}
.modeApiMain .modeMainBtn {
  width: 237px;
}
/* 正常是xl */
/* lg */
/* md */
@media (max-width: 992px) {
  .modeWebMain .modeWebBox {
    padding-bottom: 135px ;
  }
  .modeWebMain .modeWebBox::after {
    position: absolute;
    top: calc(100% - 135px / 2);
    left: 50%;
    margin-left: -23.5px;
  }
}
/* sm */
/* sx */
