/* light */
/* dark */
.topSelect {
  position: relative;
  z-index: 3;
}
.table {
  width: 100%;
  margin-bottom: 266px;
  border-radius: 6px;
  padding-bottom: 60px;
}
.tableTitle {
  width: 100%;
  padding: 43px 0 18px;
  border-bottom: 1px solid #D8D8D8;
  margin: 0;
  font-size: 14px;
}
.tableCentent {
  width: 100%;
  height: 68px;
  margin: 0;
  font-size: 14px;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 2;
  top: 0;
  box-shadow: none;
}
.tableCentent:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  top: -5px;
}
.icon {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.name {
  width: calc(100% - 32px);
  box-sizing: border-box;
  padding-left: 8px;
}
