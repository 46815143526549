/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 550px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.logTitle {
  font-size: 36px;
  padding-top: 65px;
  color: #363636;
}
.logMain {
  width: 83.33333333%;
  /* 选择登录 */
  /* 邮箱手机号登录 */
}
.logMain .logTips {
  padding: 50px 0 15px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
}
.logMain .logChangBtn {
  min-height: 200px;
  background: #F3F3F3;
  border-radius: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  transition: all 0.3s linear;
}
.logMain .logChangBtn:hover {
  background: linear-gradient(90deg, #01A8FF, #00EAFF);
  color: #fff;
}
.logMain .logUnder {
  border-top: 1px solid #E3E3E3;
  margin-top: 50px;
  padding: 42px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  text-align: center;
}
.logMain .logForm {
  width: 100%;
}
.logMain .logTex {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 20px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 18px;
  margin-top: 20px;
  box-sizing: border-box;
}
.logMain .logBtn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.logMain .logForgotPad {
  font-size: 14px;
  text-decoration: underline;
  color: #999999;
}
.logMain .fromTipsBox {
  height: 60px;
}
.logMain .fromTipsBox .fromTips {
  height: 20px;
  color: #FF0000;
  font-size: 14px;
  line-height: 20px;
}
.logMain .hasTips {
  border-color: #FF0000;
}
.logBtn:disabled {
  opacity: 0.2;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
@media (max-width: 768px) {
  .popUps .popUpsMode {
    min-height: inherit;
  }
  .popUps .popUpsMode .logTitle {
    font-size: 24px;
    padding-top: 35px;
  }
  .popUps .popUpsMode .logMain .logTips {
    padding-top: 30px;
    font-size: 12px;
  }
  .popUps .popUpsMode .logMain .logChangBtn {
    min-height: 120px;
    font-size: 16px;
  }
  .popUps .popUpsMode .logMain .logChangBtn .logBtnIcon {
    width: 50px;
    height: 50px;
  }
  .popUps .popUpsMode .logMain .logUnder {
    padding: 24px 0;
    font-size: 12px;
    margin-top: 30px;
  }
}
/* sx */
.warnTips {
  background-color: rgba(255, 158, 51, 0.1);
  color: #FF9E33;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  line-height: 20px;
  border-radius: 10px;
}
.cardBox,
.cardPicBox {
  position: relative;
  width: 100%;
  padding-bottom: 52%;
  border-radius: 10px;
  overflow: hidden;
}
.cardBox label span,
.cardPicBox label span {
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
.cardBox label .uploadImg,
.cardPicBox label .uploadImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  object-fit: cover;
}
.cardBox:hover .removeImg,
.cardPicBox:hover .removeImg {
  display: flex;
}
.cardBox .removeImg,
.cardPicBox .removeImg {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.cardBox label::before,
.cardPicBox label::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cardBox label::before {
  background: url(../../../assets/images/login/card-bg.png) no-repeat;
  background-size: 100%;
}
.cardPicBox label::before {
  background: url(../../../assets/images/login/card-bg-pic.png) no-repeat;
  background-size: 100%;
}
.logBtn:disabled {
  opacity: 0.2;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
/* sx */
.edtailMain {
  margin-top: 37px;
  margin-bottom: 237px;
}
.detailImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.detailImg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.resultImg {
  width: 148px;
  height: auto;
  border-radius: 10px;
}
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 280px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.addMetaTitle {
  position: absolute;
  font-size: 24px;
  line-height: 24px;
  top: 20px;
  width: 100%;
  padding-bottom: 14px;
  text-align: center;
}
.addMetaInput {
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 4px;
  width: 100%;
  border: 1px solid #e5e8eb;
  padding: 8px;
  font-size: 16px;
  border-radius: 6px;
}
.addMetaInput:focus {
  box-shadow: rgba(4, 17, 29, 0.25) 0px 0px 8px 0px;
  outline: none;
}
.bottomBtn {
  align-content: center;
  width: 100%;
}
.btn {
  width: 100%;
  height: 48px;
  font-size: 20px;
  border-radius: 18px;
  padding: 0;
  /* margin-top: 60px; */
  font-weight: bold;
}
.btn:disabled {
  opacity: 0.2;
}
.logMain {
  width: 83.33333333%;
}
.edtailCentent {
  box-sizing: border-box;
  width: 100%;
  padding: 32px;
  border-radius: 8px;
}
.edtailCentent .topBtn {
  text-align: center;
  height: 42px;
}
.edtailCentent .topBtn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.edtailCentent .topBtn p {
  font-size: 12px;
  color: #9F9F9F;
  padding-top: 5px;
}
.edtailCentent .counts {
  width: 20%;
  font-size: 14px;
  color: #9F9F9F;
}
.edtailCentent .counts img {
  margin-right: 7px;
}
.edtailCentent .detailDesc {
  font-size: 14px;
  color: #9F9F9F;
  line-height: 28px;
}
.edtailCentent .detailData {
  width: 20%;
}
.edtailCentent .detailData p {
  color: #9F9F9F;
  font-size: 14px;
}
.edtailCentent .detailData h3 {
  font-size: 18px;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btnImg {
  animation: rotation 1s linear infinite;
}
.edtailCard {
  border-radius: 8px;
}
.edtailCard .edtailCardTitle {
  width: 100%;
  height: 76px;
  border-bottom: 2px solid #D8D8D8;
  box-sizing: border-box;
  padding: 0 24px;
  z-index: 20;
  position: relative;
  cursor: pointer;
}
.edtailCard .edtailCardTitle h3 {
  font-size: 24px;
}
.edtailCard .edtailCardTitle img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  margin-right: 12px;
}
.edtailCard .edtailCardCentent {
  box-sizing: border-box;
  padding: 24px;
}
.edtailCard .edtailCardCentent .edtailCardBox {
  width: 100%;
  padding: 15px 0;
  background-color: rgba(21, 178, 229, 0.06);
  border: 1px solid #15b2e5;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 16px;
  color: #000;
}
.edtailCard .edtailCardCentent .edtailCardBox p {
  font-size: 12px;
}
.edtailCard .edtailCardCentent .edtailCardBox h3 {
  font-size: 14px;
}
.edtailCard .edtailCardCentent .edtailCardDesc {
  font-size: 14px;
  color: #9F9F9F;
  line-height: 28px;
  font-weight: normal;
  position: relative;
}
.edtailCard .edtailCardCentent .edtailCardDesc .userAvatar {
  width: 32px;
  height: 32px;
  border-radius: 32px;
}
.edtailCard .edtailCardCentent .edtailCardDesc .edtailBtn {
  padding: 5px 25px;
  font-size: 14px;
}
.edtailCard .edtailCardCentent .detailsCardTips {
  padding: 0 0 30px;
  font-size: 14px;
}
.edtailCard .edtailCardCentent .detailsCardTips:last-child {
  padding: 0;
}
.edtailCard .edtailCardCentent .detailsCardTips .detailsCardTipsTitle {
  width: 40%;
}
.edtailCard .userIcon {
  width: 36px;
  height: 36px;
}
.tableCentent {
  width: 100%;
  height: 68px;
  margin: 0;
  font-size: 14px;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 2;
  top: 0;
  box-shadow: none;
}
.tableCentent:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  top: -5px;
}
.actionImg {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.actionTitle {
  padding-left: 0;
}
.mask {
  position: absolute;
  inset: 0px;
  z-index: 1;
  transition: opacity 0.1s ease-in 0s;
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}
.mainCtn {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}
.statsItem {
  border-radius: 10px;
  padding: 24px;
  margin-top: 8px;
}
.statsItem .numTrait {
  display: flex;
  font-weight: 500;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.statsItem .numTrait .traitType {
  color: #707a83;
}
.statsItem .numTrait .value {
  color: #707a83;
  min-width: fit-content;
  padding-left: 4px;
}
.statsItem .bar {
  background-color: #fbfdff;
  border: 1px solid #e5e8eb;
  border-radius: 14px;
  height: 14px;
  margin-top: 4px;
  overflow: hidden;
}
.statsItem .bar .barFill {
  background-color: #2081e2;
  height: 100%;
}
.musicImg {
  -webkit-transform: rotate(360deg);
  animation: rotation 3s linear infinite;
  -moz-animation: rotation 3s linear infinite;
  -webkit-animation: rotation 3s linear infinite;
  -o-animation: rotation 3s linear infinite;
}
.buyBtn,
.noBuyBtn {
  padding: 8px 15px !important;
}
.buyBtn:disabled,
.noBuyBtn:disabled {
  opacity: 0.2;
}
.noBuyBtn {
  background-color: #666 !important;
  color: #fff !important;
}
.marketData h3 {
  width: 48px;
  text-align: center;
  line-height: 0.75;
}
.copyIconDetail {
  width: 16px;
  height: 16px;
}
.copyIconDetail img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
font {
  font-weight: 400 !important;
}
