/* light */
/* dark */
.cardBg {
  position: relative;
}
.cardBg::after {
  content: '';
  display: block;
  width: calc(100% - 16px);
  height: 100%;
  position: absolute;
  top: 3px;
  left: 8px;
  background: #fff;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}
.card {
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #D1D1D1;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.1);
  margin-bottom: 16px;
  transition: all 0.2s ease-in;
  position: relative;
  top: 0;
  z-index: 2;
}
.card:hover {
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.4);
  top: -5px;
  border-color: #01A8FF;
}
.card .cardImg {
  width: 100%;
  padding: 15px 15px 0;
  box-sizing: border-box;
  position: relative;
}
.card .cardImg .image {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.card .trueImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}
.card .cardTips {
  width: 100%;
  margin: 0 auto !important;
  padding: 0 7px;
}
.card .cardTips.cardFooter {
  background: #F2F4F6;
  padding: 5px 7px;
  border-radius: 0 0 10px 10px;
}
.card .cardTips .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  height: 48px;
}
.card .cardTips .price {
  line-height: 48px;
}
.card .cardTips .orginInfo {
  font-size: 14px;
  height: 36px;
}
.card .cardTips .orginInfo .orginInfoImg {
  width: 22px;
  height: 22px;
  border-radius: 22px;
}
.card .cardTips .orginInfo .orginInfoImg img {
  width: 22px;
  height: 22px;
}
.card .cardTips .orginInfo .orginInfoName {
  width: calc(100%-24px);
  box-sizing: border-box;
  padding-left: 7px;
  line-height: 22px;
}
.card .cardTips .orginInfo .orginInfoSymbol {
  text-align: right;
  font-size: 16px;
  line-height: 22px;
}
.exploreItem {
  flex-basis: 20%;
  max-width: 20%;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 24px;
  line-height: 24px;
}
.detailTips {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
  margin: 0;
}
.sortDetailTips img {
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 16px 0px rgba(34, 23, 20, 0.2);
}
.detailBannerMain {
  width: 100%;
  height: 0;
  min-height: 0 !important;
  padding-bottom: 100%;
  position: relative;
  background-color: #E1E1E1;
}
.detailBannerMain .detailBanner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.detailBannerMain .detailBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mask {
  position: absolute;
  inset: 0px;
  transition: opacity 0.1s ease-in 0s;
  opacity: 1;
  background: rgba(0, 0, 0, 0.4);
}
.mediaImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -2;
  position: absolute;
}
.itemTips {
  position: absolute;
  bottom: 8px;
  right: 25px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 8px 15px;
  border-radius: 20px;
}
/* 正常是xl */
/* lg */
@media (max-width: 1200px) {
  .exploreItem {
    flex-basis: 25%;
    max-width: 25%;
  }
}
/* md */
@media (max-width: 992px) {
  .exploreItem {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
}
/* sm */
@media (max-width: 768px) {
  .exploreItem {
    flex-basis: 50%;
    max-width: 50%;
  }
}
/* sx */
@media (max-width: 576px) {
  .exploreItem {
    flex-basis: 100%;
    max-width: 100%;
  }
}
