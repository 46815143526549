/* light */
/* dark */
.bannerUser {
  height: 220px;
}
.bannerUserElem {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.bannerUserElem .bannerUserTitle {
  font-size: 32px;
  top: 40%;
}
.bannerUserElem .bannerUserTtext {
  top: 40%;
}
.bannerAnimElem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-size: contain;
}
