/* light */
/* dark */
.fuelMain {
  position: relative;
}
.fuelMain::before {
  content: '';
  display: block;
  width: 100%;
  height: 310px;
  background: url(../../assets/images/fuel/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
}
.fuelTop {
  position: relative;
  top: -160px;
  width: 100%;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
}
.fuelTop .fuelBuy {
  padding: 50px;
  box-sizing: border-box;
}
.fuelTop .fuelTips {
  padding: 26px 50px 40px;
  box-sizing: border-box;
  border-top: 1px solid #E8E8E8;
}
.buyFuelBtn {
  width: 203px;
  box-shadow: 0px 3px 6px 1px rgba(7, 123, 252, 0.18);
  border-radius: 40px !important;
  color: #fff;
  padding: 10px !important;
}
.fuelBillList {
  position: relative;
  top: -160px;
  width: 100%;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  box-sizing: border-box;
  padding: 30px;
}
.fuelBillList .fuelBillListTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid #077BFC;
}
.listTitle {
  background: #F6F6F6;
  height: 46px;
  line-height: 46px;
}
.fuelImg {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}
.orginPagination li a {
  line-height: 30px !important;
}
.orginPagination li button::after {
  font-size: 24px;
  line-height: 24px;
}
