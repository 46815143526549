/* light */
/* dark */
.bannerWrap {
  position: relative;
  height: 360px;
  margin-top: 15px;
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.txtWrap {
  position: absolute;
  left: 45px;
  top: 130px;
}
.txtO {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 42px;
}
.txtT {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 25px;
  margin-top: 5px;
}
.title {
  height: 50px;
  font-size: 36px;
  font-weight: 500;
  color: #363636;
  line-height: 50px;
}
.content {
  box-sizing: border-box;
  padding: 30px;
}
.boxMain {
  border-radius: 15px;
  min-height: 660px;
}
.boxMain .boxMainLeft {
  border-right: 1px solid #e1e1e1;
}
.leftNav li {
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 10px;
  height: 36px;
  line-height: 36px;
  margin-top: 10px;
}
.leftNav li.cur {
  background-color: #00A8FF;
  color: #fff;
  border-radius: 5px;
}
.collapseMian {
  width: 100%;
  background-color: #e6e6e6;
}
