/* light */
/* dark */
.popUps {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.popUps .popBg {
  display: block;
  background: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}
.popUps .popClose {
  width: 22px;
  height: 22px;
  background: url('../../../assets/images/login/close.png') no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in;
  text-indent: -9999px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 9;
  cursor: pointer;
  transform: rotate(0deg);
}
.popUps .popClose:hover {
  background: url('../../../assets/images/login/close-hover.png') no-repeat;
  background-position: center center;
  transform: rotate(180deg);
}
.popUps .popUpsMode {
  background-color: #fff;
  min-height: 550px;
  position: relative;
  z-index: 2;
  border-radius: 20px;
}
.popUps .popUpsMode .modeTitle {
  font-size: 24px;
  padding: 15px;
  color: #363636;
}
.popUps .popUpsMode .modeMain {
  width: 100%;
  height: calc(100%-100px);
  margin: 0;
}
.popUps .popUpsMode .modeMain .exhibitImg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 10px;
  background-color: #F3F3F3;
}
.popUps .popUpsMode .modeMain .exhibitImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg {
  width: 120px;
  height: 120px;
  min-width: 120px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadModel {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadModel img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg:hover .uploadExhibitImgBtn {
  display: flex;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn {
  display: none;
  width: 36px;
  height: 100%;
  background: rgba(243, 243, 243, 0.92);
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 10px 10px 0;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn .upAddBtn {
  width: 100%;
  height: 100%;
  text-indent: -99999px;
  background: url(../../../assets/images/apps/add-icon.png) no-repeat;
  background-position: center;
  transition: all 0.2s ease-in;
  position: relative;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn .upAddBtn:hover {
  background: url(../../../assets/images/apps/add-hover-icon.png) no-repeat;
  background-position: center;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn .upAddBtn::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  height: 1px;
  bottom: 0;
  background-color: #CCCCCC;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn .upDeleteBtn {
  background: #f00;
  width: 100%;
  height: 100%;
  text-indent: -99999px;
  background: url(../../../assets/images/apps/delete-icon.png) no-repeat;
  background-position: center;
  transition: all 0.2s ease-in;
}
.popUps .popUpsMode .modeMain .uploadExhibitImg .uploadExhibitImgBtn .upDeleteBtn:hover {
  background: url(../../../assets/images/apps/delete-hover-icon.png) no-repeat;
  background-position: center;
}
.popUps .popUpsMode .modeMain .uploadfileTips {
  word-wrap: break-word;
  line-height: 24px;
}
.popUps .popUpsMode .modeMain .createBtn {
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  background-color: #2081e2;
  border: 1px solid #2081e2;
  color: #ffffff;
}
.popUps .popUpsMode .modeMain .createBtn:disabled {
  opacity: 0.2;
}
.popUps .popUpsMode .modeMain .required.field > label:after {
  margin: -4px 0em 0em 4px;
  content: '*';
  color: #DB2828;
  display: inline-block;
  vertical-align: top;
}
.popUps .popUpsMode .modeMain .field > label {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}
.popUps .popUpsMode .modeMain .field > p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #707a83;
}
.popUps .popUpsMode .modeMain .field:last-child {
  margin-bottom: 0em;
}
.popUps .popUpsMode .modeMain textarea {
  width: 100%;
  margin: 0em;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 10px 14px;
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 0px 0px transparent inset;
  box-shadow: 0px 0px 0px 0px transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 16px;
  line-height: 18px;
  resize: vertical;
  vertical-align: top;
}
.popUps .popUpsMode .modeMain textarea:not([rows]) {
  height: 120px;
  min-height: 64px;
  max-height: 280px;
}
.popUps .popUpsMode .modeMain input[type="text"] {
  width: 100%;
  vertical-align: top;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 24px;
  padding: 6px 14px;
  font-size: 14px;
  background: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 8px;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}
/* 正常是xl */
/* lg */
/* md */
/* sm */
@media (max-width: 768px) {
  .popUps .popUpsMode {
    min-height: inherit;
  }
  .popUps .popUpsMode .modeTitle {
    font-size: 18px;
  }
  .popUps .popUpsMode .logMain .logTips {
    padding-top: 30px;
    font-size: 12px;
  }
  .popUps .popUpsMode .logMain .logChangBtn {
    min-height: 120px;
    font-size: 16px;
  }
  .popUps .popUpsMode .logMain .logChangBtn .logBtnIcon {
    width: 50px;
    height: 50px;
  }
  .popUps .popUpsMode .logMain .logUnder {
    padding: 24px 0;
    font-size: 12px;
    margin-top: 30px;
  }
}
/* sx */
.myGridBox {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.field {
  width: 100%;
  position: relative;
  z-index: 999;
}
.saleStatusMerge div {
  line-height: 24px;
}
.saleStatusMerge::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border: 1px solid #333;
  background: #D5D5D5;
  box-sizing: border-box;
}
.saleStatusMerge.saleStatus0::before {
  background: #01a8ff;
}
.saleStatusMerge.saleStatus1::before {
  background: #ffbb00;
}
.saleStatusMerge.saleStatus2::before {
  background: #33CC66;
}
.saleStatusMerge.saleStatus3::before {
  background: #33CC66;
  border: 2px solid #FF48ED;
}
